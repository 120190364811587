import React from 'react';

import { fetchRequestHistory } from '../../../services/api/offline-consult';

import { LoadingContainer, LoadingIcon } from './StyledComponents';
import { Row, Col } from 'reactstrap';
import {
  HistoryItem,
  TimeContainer,
  StyledHistoryContainer,
} from '../../common/Activities';

export default class HistoryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      history: [],
    };
  }

  getHistory = (requestId) => {
    return fetchRequestHistory(requestId)
      .then((response) => {
        if (response && response.message === 'success') {
          return response.data;
        }
        throw new Error('Response Message is not correct');
      })
      .catch((err) => {
        console.error(err, 'Error while fetching data');
      });
  };

  componentDidMount() {
    const { requestId } = this.props;

    this.getHistory(requestId)
      .then((data) => {
        console.log({ data, requestId }, 'History');
        this.setState({
          history: data.history,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { history, loading } = this.state;
    return loading ? (
      <LoadingContainer>
        <LoadingIcon></LoadingIcon>
      </LoadingContainer>
    ) : (
      <StyledHistoryContainer
        style={{
          borderRadius: '12px',
        }}
      >
        {/* <Row
          style={{
            padding: '5px',
          }}
        >
          <Col>
            <strong>Statuses</strong>
          </Col>
          <Col
            style={{
              textAlign: 'right',
            }}
          >
            <strong>Timings</strong>
          </Col>

        </Row> */}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '5px',
            borderBottom: '1px solid #0000003b',
          }}
        >
          <strong>Statuses</strong>
          <strong>Timings</strong>
        </div>
        {history && history.length > 0 ? (
          history.map((item) => (
            <HistoryItem key={item.activityId}>
              {item.description} by {item.userName}{' '}
              {item.meta && `(${item.meta})`}{' '}
              {item.audioFileLink && (
                <a href={item.audioFileLink} target="_blank">
                  Link
                </a>
              )}{' '}
              {item.callReason != null ? `(${item.callReason})` : ''}{' '}
              <TimeContainer>{item.eventTime}</TimeContainer>
            </HistoryItem>
          ))
        ) : (
          <HistoryItem>No Activities Here</HistoryItem>
        )}
      </StyledHistoryContainer>
    );
  }
}
