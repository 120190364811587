import { debounce } from '../../../utils/debounce';
import React, { useMemo } from "react"
import { Button, Tooltip, UncontrolledTooltip } from "reactstrap"
import StatusFilter from '../headerFilters/StatusFilter';
import { StatusCompleteContainer, StatusDangerContainer, StatusPendingContainer } from '../../common/StatusContainer';
import { IoIosCheckmark, IoMdChatboxes, IoMdClose, IoMdCreate, IoMdPerson } from 'react-icons/io';
import moment from 'moment';
import DateFilter from '../headerFilters/DateFilter';
import AppointmentTimeFilter from '../headerFilters/AppointmentTimeFilter';
import CallingButton from '../../offline-consultations/CallingButton';
import AckCallFilter from '../headerFilters/AckCallFilter';
import ClaimedByFilter from '../../offline-consultations/headerFilter/ClaimedByFilter'
import { FaArrowCircleUp } from 'react-icons/fa';

const commonFields = (accessor, header, numeric = false) => ({
    Header: header,
    accessor: accessor,
    disablePadding: true,
    style: { whiteSpace: 'unset' },
    numeric: numeric
})

const useTableColumns = (functions, values, deps) => {
    const { 
        dispatch, showVaccinationRequestModal, setRequestIdFilter, setParentIdFilter, setStatusFilter,
        confirmOpenModal, cancelOpenModal, setRequestDateFilter, setAppTimeFilter,
        setAppointmentDateFilter, setAckButtonConfirmModal, setAckFilter,
        openClaimRequestButton, openEditPaymentButton, openRequestCommentModal,
        openShiftRequestButton,setRequestCenterFilter,setRequestVaccinationFilter,setRequestSponsorFilter
    } = functions
    const {
        statusFilter
    } = values
    const tableColumns = useMemo(() => [
        {
            Header: 'Cashless Vaccination Request Details',
            foldable: true,
            columns: [
                {
                    ...commonFields('requestId', 'RID', true),
                    Cell: (row) => {
                        const { requestId } = row.original
                        return <div>
                            <Button
                                type='submit'
                                size='sm'
                                color='primary'
                                onClick={() => dispatch(showVaccinationRequestModal(requestId))}
                            >
                                {requestId}
                            </Button>
                        </div>
                    },
                    Filter: () => (
                        <input 
                            onChange={(event) => {
                                debounce(setRequestIdFilter(event.target.value), 300)
                            }}
                        />
                    )
                },
                {
                    ...commonFields('parentBookingID', 'Parent Order ID', true),
                    Cell: (row) => {
                        const { parentBookingID } = row.original
                        return <div>
                                {parentBookingID}
                        </div>
                    },
                    Filter: () => (
                        <input 
                            onChange={(event) => {
                                debounce(setParentIdFilter(event.target.value), 300)
                            }}
                        />
                    )
                },
                {
                    ...commonFields('partnerName', 'Partner'),
                    // Filter: ({ filter, onChange }) => (
                    //     <ClaimedByFilter 
                    //         value={filter ? filter.value : ''}
                    //         onChange={onChange}
                    //     />
                    // )
                },
                {
                    ...commonFields('opsUserName', 'Claimed By'),
                    Filter: ({ filter, onChange }) => (
                        <ClaimedByFilter 
                            value={filter ? filter.value : ''}
                            onChange={onChange}
                        />
                    )
                },
                {
                    ...commonFields('sponsorName', 'Sponsor'),
                    filterable:true,
                    Filter: () => (
                        <input 
                            onChange={(event) => {
                                debounce(setRequestSponsorFilter(event.target.value), 300)
                            }}
                        />
                    )
                },
                {
                    ...commonFields('status', 'Status'),
                    Filter: () => (
                        <StatusFilter 
                            onChange={setStatusFilter}
                            value={statusFilter}
                        />
                    ),
                    Cell: (row) => {
                        const { status, cancelledReason } = row.original
                        if ('request-received' === (status)) {
                            return <StatusPendingContainer>{status}</StatusPendingContainer>
                        } else if ('request-cancelled' === status) {
                            return <><StatusDangerContainer>{status}</StatusDangerContainer><span>{`(${cancelledReason})`}</span></>
                        } else {
                            return <StatusCompleteContainer>{status}</StatusCompleteContainer>
                        }
                    }
                },
                {
                    ...commonFields('actions', 'Actions', true),
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                        return <div>
                            {['request-received', 'request-confirmed'].includes(row.original.status) ? <><IoIosCheckmark
                                className='icon-style'
                                style={{ fontSize: '25px' }}
                                id={`confirmButton-${row.original.requestId}`}
                                onClick={() => confirmOpenModal(row.original.requestId)}
                            />
                            <UncontrolledTooltip target={`confirmButton-${row.original.requestId}`}>
                                Confirm Appointment
                            </UncontrolledTooltip></> : <></>}
                            {['request-received', 'request-confirmed', 'payout-completed'].includes(row.original.status) ? <><IoMdClose
                                className='icon-style'
                                id={`cancelButton-${row.original.requestId}`}
                                onClick={() => cancelOpenModal(row.original.requestId)}
                            />
                            <UncontrolledTooltip target={`cancelButton-${row.original.requestId}`}>
                                Cancel Appointment
                            </UncontrolledTooltip></> : <></>}
                            {!row.original.opsUserName ? <><IoMdPerson 
                                className='icon-style'
                                id={`claimButton-${row.original.requestId}`}
                                onClick={() => openClaimRequestButton(row.original.requestId)}
                            />
                            <UncontrolledTooltip target={`claimButton-${row.original.requestId}`}>
                                Claim Request
                            </UncontrolledTooltip></> : <></>}
                            <>
                                <IoMdCreate 
                                    className='icon-style'
                                    id={`claimButton-${row.original.requestId}`}
                                    onClick={() => openEditPaymentButton(row.original.requestId)}
                                />
                                <UncontrolledTooltip target={`claimButton-${row.original.requestId}`}>
                                    Edit Payment
                                </UncontrolledTooltip>
                            </>
                            <FaArrowCircleUp 
                                className='icon-style'
                                id={`shiftButton-${row.original.requestId}`}
                                onClick={() => openShiftRequestButton(row.original.requestId)}
                            />
                            <UncontrolledTooltip target={`shiftButton-${row.original.requestId}`}>
                                Shift To Pending
                            </UncontrolledTooltip>
                        </div>
                    }
                },
                {
                    ...commonFields('requestDate', 'Req. Date'),
                    headerStyle: { overflow: 'visible' },
                    Cell: (row) => {
                        const { requestDate } = row.original
                        return moment(requestDate).format('DD MMM hh:mm A')
                    },
                    Filter: () => (
                        <DateFilter 
                            label='Request'
                            uniqueId='fsfdf343s'
                            onChooseDate={setRequestDateFilter}
                        />
                    )
                },
                {
                    ...commonFields('appointmentDate', 'Appt. Date'),
                    headerStyle: { overflow: 'visible' },
                    Filter: () => (
                        <DateFilter 
                            label='Appointment'
                            uniqueId='rew543fsd'
                            onChooseDate={setAppointmentDateFilter}
                        />
                    ),
                    Cell: (row) => {
                        const { appointmentDate } = row.original
                        return appointmentDate ? 
                        moment(appointmentDate, 'YYYY-MM-DD').format('DD MMM') :
                        ''
                    }
                },
                {
                    ...commonFields('appointmentTime', 'Appt. Time'),
                    headerStyle: { overflow: 'visible' },
                    Cell: (row) => {
                        const { appointmentTime } = row.original
                        try {
                            return appointmentTime ? 
                            appointmentTime.split(' - ')
                            .map(t => moment(t, 'hh:mm:ss A').format('hh:mm A'))
                            .join(' - ')
                            : ''
                        } catch (err) {
                            return appointmentTime || ''
                        }
                    },
                    Filter: () => (
                        <AppointmentTimeFilter
                            handleChange={(timingId, slotId) => {
                                setAppTimeFilter([timingId, slotId])
                            }}
                        />
                    )
                },
                {
                    ...commonFields('categoryName', 'Category'),
                    filterable: false,
                    Cell: (row) => {
                        const { categoryName } = row.original
                        return categoryName || 'N/A'
                    }
                },
                {
                    ...commonFields('subCategoryName', 'Vaccination Name'),
                    filterable:true,
                    Filter: () => (
                        <input 
                            onChange={(event) => {
                                debounce(setRequestVaccinationFilter(event.target.value), 300)
                            }}
                        />
                    )
                },
                {
                    ...commonFields('patientName', 'Patient'),
                    filterable: false
                },
                {
                    ...commonFields('networkCenterName', 'Center'),
                    filterable:true,
                    Filter: () => (
                        <input 
                            onChange={(event) => {
                                debounce(setRequestCenterFilter(event.target.value), 300)
                            }}
                        />
                    )
                },
                {
                    ...commonFields('acknowledgementCall', 'Ack. Call'),
                    Cell: (row) => {
                        const {
                            requestId,
                            userPhone,
                            acknowledgementCallCount,
                            acknowledgementCallDuration,
                        } = row.original
                        let status = 'Pending'
                        
                        if (
                            acknowledgementCallDuration &&
                            acknowledgementCallDuration !== '0s'
                        ) {
                            status = `Yes (${acknowledgementCallDuration}, ${acknowledgementCallCount})`;
                            return (
                                <StatusCompleteContainer>
                                    {status}
                                    {userPhone && (
                                        <CallingButton
                                            onSubmit={() => setAckButtonConfirmModal(requestId)}
                                        />
                                    )}
                                </StatusCompleteContainer>
                            );
                        } else if (
                            acknowledgementCallCount && 
                            parseInt(acknowledgementCallCount) !== 0
                        ) {
                            status = `No Answer (${acknowledgementCallCount})`;
                            return (
                            <StatusPendingContainer>
                                {status}
                                {userPhone && (
                                    <CallingButton
                                        onSubmit={() => setAckButtonConfirmModal(requestId)}
                                    />
                                )}
                            </StatusPendingContainer>
                            );
                        }
                        return (
                            <StatusDangerContainer>
                                {status}
                                {userPhone && (
                                    <CallingButton
                                        onSubmit={() => setAckButtonConfirmModal(requestId)}
                                    />
                                )}
                            </StatusDangerContainer>
                        )
                    },
                    Filter: () => (
                        <AckCallFilter 
                            onChange={setAckFilter}
                        />
                    )
                },
                {
                    ...commonFields('payout', 'Payout'),
                    filterable: false,
                    Cell: (row) => {
                        const { payoutStatus } = row.original
                        return payoutStatus || 'Not Raised'
                    }
                },
                {
                    ...commonFields('commentsCount', 'Comments'),
                    filterable: false,
                    sortable: false,
                    Cell: (row) => {
                        const { requestId, commentsCount } = row.original
                        return (
                            <React.Fragment>
                                <div onClick={() => openRequestCommentModal(requestId)}>
                                <p>
                                    <span id={`commentTooltip-a-${requestId}`}>
                                    {commentsCount}
                                    <IoMdChatboxes
                                        className="icon-chat"
                                        style={{ cursor: 'pointer', width: '30px', height: '30px' }}
                                    />
                                    </span>
                                </p>
                                <UncontrolledTooltip target={`commentTooltip-a-${requestId}`}>
                                    View Comments
                                </UncontrolledTooltip>
                                </div>
                            </React.Fragment>
                        )
                    }
                }
            ]
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], deps);
    
    return tableColumns
}

export default useTableColumns