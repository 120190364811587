import React, { Component } from 'react';
import NavBar from '../layout/NavBar';
import EditConsultation from '../common/modal/EditConsultation';
import { withRouter } from 'react-router-dom';
import { debounce } from '../../utils/debounce';
import loginCheck from '../login/LoginCheck';
import ConsultationViewModal from './ConsultationViewModal';
import BookConsultationModal from './BookConsultationModal';
import {
  // setDocConsultConsultId,
  setVerticalsInRedux,
  setDoctorsInRedux,
  setSponsorsInRedux,
  setGroupOfInRedux,
  setStartDateInRedux,
  setEndDateInRedux,
  setInternalInRedux,
  setPageIndexInRedux,
  setPageSizeInRedux,
} from '../../actions/index';
import { connect } from 'react-redux';


import Link from 'valuelink';

import {
  getUserAndPatientDocOnCallData,
  getAllDocOnCallDataCount,
  getUserAndPatientOnlineSpecialistData,
  getUserAndPatientOfflineSpecialistData,
  getScheduledConsultationsData,
} from '../../services/api/doc-on-call';

import DocOnCallTable from './DocOnCallTable';

import {
  OuterComponent,
  PaddedContainer,
  SubPaddedContainer,
  SearchBox,
} from '../common/modal/StyledComponent';

import Select from 'react-select';
import MultiVerticalFilter from '../offline-consultations/headerFilter/MultiVerticalFilter';
import MultiDoctorFilter from '../offline-consultations/headerFilter/MultiDoctorFilter';
import MultiSponsorFilter from '../offline-consultations/headerFilter/MultiSponsorFilter';

import DownloadCsv from '../common/DownloadCsv';
import { Button, ButtonGroup } from 'reactstrap';

const allowedCsvColumnsForScheduled = [
  'id',
  'scheduledDateTime',
  'doctorId',
  // 'isBooked',
  'bookedConsultationId',
  'timeCreated',
  'cancelledOn',
  'cancelReason',
  'opsNote',
  'errorMessage',
  'userName',
  'sponsorName',
  'doctorVertical',
  'doctorName',
  'patientName',
  'userPhone',
];

const allowedCsvColumns = [
  'id',
  'timeCreated',
  'platform',
  //  'cancelledOn',
  'prescriptions',
  'totalDuration',
  'totalConnectionRequest',
  'completedConnectionRequest',
  'scheduledAt',
  'totalDocCallAttempts',
  'doctorId',
  'requestedCallType',
  'closedByDoctor',
  'endReason',
  'tat',
  'callsDuration',
  'callsCount',
  'videoCallDuration',
  'videoCallCount',
  'opsNote',
  'lastConnectionRequest',
  'endedOn',
  'doctorTTCount',
  'userTTCount',
  'amount',
  'status',
  'labels',
  'userName',
  'sponsorName',
  'sponsorId',
  'doctorVertical',
  'doctorName',
  'patientName',
  'userPhone',
  'lastCallStatus',
  'lastCommentTime',
  'totalVideoConnectionRequest',
  'completedVideoConnectionRequest',
  'lastVideoRequestTimestamp',
  'userLastMonthConsultsCount',
  'rxTat',
  'consultationLanguage'
];

class DoctorOnCall extends Component {
  constructor(props) {
    super(props);
    this.debounceSearch = debounce(
      (val) => this.fetchUserAndPatientTableData(),
      0
    );

    this.state = {
      editConsultModalIsOpen: false,
      showConsultModalIsOpen: false,
      bookConsultModalIsOpen: false,
      search: '',
      pages: 0,
      pageIndex: this.getPageIndexInitial() ? this.getPageIndexInitial() : 0,
      pageSize: this.getPageSizeInitial() ? this.getPageSizeInitial() : 100,

      filters: {},

      exFilters: {
        consultationDate: {
          startDate: this.getStartDateValue(),
          endDate: this.getEndDateValue(),
        },
      },

      showModal: false,
      consultationData: null,
      loading: true,
      data: [],
      dataCsv: [],
      onlyVipUsers: null,
      consultationType: props.consultationType,
      consultViewConsultationType: props.consultationType,
      currentRowUserId: null,

      groupOf: this.getGroupOfValue(),
      verticals: [],
      verticalFilterDisplay: 'block',

      internalFilter: {
        id: '',
        prescriptions: this.getInternalInitial('prescriptions')
          ? this.getInternalInitial('prescriptions')
          : 'all',
        status: this.getInternalInitial('status')
          ? this.getInternalInitial('status')
          : '',
        connectionRequest: this.getInternalInitial('connectionRequest')
          ? this.getInternalInitial('connectionRequest')
          : 'all',
        schedule: this.getInternalInitial('schedule')
          ? this.getInternalInitial('schedule')
          : 'all',
      },
    };

    this.group_names = [
      { value: 'all', label: 'All' },
      { value: 'general', label: 'General' },
      { value: 'specialist', label: 'Specialist' },
      { value: 'advisor', label: 'Advisor' },
    ];

    this.verticalGroup = {
      all: [
        2, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 18, 19, 20, 23, 24, 28, 31, 32,
        33, 41, 42, 43, 44, 47, 48, 1, 26, 45, 46, 3,
      ],
      general: [3],
      specialist: [
        2, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 18, 19, 20, 23, 24, 28, 31, 32,
        33, 41, 42, 43, 44, 47, 48,
      ],
      advisor: [1, 4, 26, 45, 46],
    };
  }

  getCsvList = (responseBody) => {
    const csvList = [];

    responseBody.data.forEach((value, index) => {
      let tempObject = {};
      for (const [keyInner, valueInner] of Object.entries(value)) {
        if (
          (this.state.consultationType !== 'scheduled-consults' &&
            allowedCsvColumns.includes(keyInner)) ||
          (this.state.consultationType === 'scheduled-consults' &&
            allowedCsvColumnsForScheduled.includes(keyInner))
        ) {
          tempObject[keyInner] = valueInner;
        }
      }
      csvList.push(tempObject);
    });
    return csvList;
  };

  // Fetch Table Data
  onFetchData = (state) => {
    // On React Table Fetch Data which set state in this component
    let orderBy, orderDir;
    if (state.sorted.length > 0) {
      orderBy = state.sorted[0].id;
      orderDir = state.sorted[0].desc ? 'desc' : 'asc';
    } else {
      orderBy = 1;
      orderDir = 'desc';
    }

    const internalFilters = {};
    state.filtered.forEach((filter) => {
      if (filter.id === 'scheduledAt') {
        internalFilters['schedule'] = filter.value;
      } else if (filter.id === 'vertical') {
        internalFilters['verticalId'] = filter.value.value;
      } else {
        internalFilters[filter.id] = filter.value;
      }
    });

    console.log(state, internalFilters, 'onFetchData(state)');

    const { dispatch } = this.props;
    // Dispatch Internal Filters here
    dispatch(
      setInternalInRedux(
        { ...this.state.internalFilter, ...internalFilters },
        window.location.pathname
      )
    );

    this.setState(
      {
        pageSize: state.pageSize,
        page: state.page,
        pageIndex: state.page,
        orderBy,
        orderDir,
        filters: internalFilters,
        internalFilter: {
          ...this.state.internalFilter,
          ...internalFilters,
        },
      },
      this.fetchUserAndPatientTableData
    );

    dispatch(setPageIndexInRedux(state.page, window.location.pathname));
    dispatch(setPageSizeInRedux(state.pageSize, window.location.pathname));
  };

  fetchUserAndPatientTableData = () => {
    // gets data from state and fetch from api then set rows in state
    this.setState({
      loading: true,
    });

    console.log(
      'fetchUserAndPatientTableData()',
      // params,
      // this.state.filters,
      // this.state.exFilters,
      this.state.internalFilter,
      // this.state.consultationType,
      'Filters before api request'
    );

    const params = {
      draw: this.state.pageSize,
      page: this.state.pageIndex,
      orderBy: this.state.orderBy,
      orderDir: this.state.orderDir,
      filters: {
        ...this.state.filters,
        ...this.state.internalFilter,
        ...this.state.exFilters,
      },
      consultationType: this.state.consultationType,
    };
    // exception for params:status on one specific value
    if (params && params.filters && params.filters.status) {
      if (params.filters.status === 'all') {
        params.filters.status = '';
      }
    }

    if(this.state.onlyVipUsers && this.state.onlyVipUsers === true){
      params.onlyVipUsers = true;
    }

    switch (this.state.consultationType) {
      case 'doc-on-call':
        this.fetchDocOnCallTableData(params);
        break;
      case 'offline-sp':
        this.fetchOfflineSpecialistTableData(params);
        break;
      case 'online-sp':
        this.fetchOnlineSpecialistTableData(params);
        break;
      case 'scheduled-consults':
        this.fetchScheduledConsultationsTableData(params);
        break;
      default:
        break;
    }
  };

  fetchDocOnCallTableData = (params) => {
    if (this.state.search && this.state.search !== '') {
      params.search = this.state.search;
    }

    getUserAndPatientDocOnCallData(params)
      .then((responseBody) => {
        const csvList = this.getCsvList(responseBody);

        this.setState({
          data: responseBody.data,
          dataCsv: csvList,
          pages: 10,
          loading: false,
        });
        // return getAllDocOnCallDataCount(params);
      })
      .then((responseBody) => {
        this.setState({
          // pages: Math.ceil(responseBody.totalHits / this.state.pageSize),
          pages: 10,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  fetchScheduledConsultationsTableData = (params) => {
    console.log(params, 'fetchScheduledConsultationsTableData(params):AFTER');

    if (this.state.search && this.state.search !== '') {
      params.search = this.state.search;
    }

    getScheduledConsultationsData(params)
      .then((responseBody) => {
        console.log(responseBody.data, 'getScheduledConsultationsData(params)');
        const csvList = this.getCsvList(responseBody);
        this.setState({
          data: responseBody.data,
          dataCsv: csvList,
          pages: 10,
          loading: false,
        });
      })
      .then((responseBody) => {
        this.setState({
          // pages: Math.ceil(responseBody.totalHits / this.state.pageSize),
          pages: 10,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  fetchOnlineSpecialistTableData = (params) => {
    console.log(params, 'fetchOnlineSpecialistTableData(params):AFTER');

    if (this.state.search && this.state.search !== '') {
      params.search = this.state.search;
    }

    getUserAndPatientOnlineSpecialistData(params)
      .then((responseBody) => {
        console.log(
          responseBody.data,
          'getUserAndPatientOnlineSpecialistData(params)'
        );
        const csvList = this.getCsvList(responseBody);
        responseBody.data.map((res, i) => {
          if (!res.requestedCallType) {
            responseBody.data[i].requestedCallType = 'N/A';
          }
        });
        this.setState({
          data: responseBody.data,
          dataCsv: csvList,
          pages: 10,
          loading: false,
        });

        // return getAllDocOnCallDataCount(params);
      })
      .then((responseBody) => {
        this.setState({
          // pages: Math.ceil(responseBody.totalHits / this.state.pageSize),
          pages: 10,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  fetchOfflineSpecialistTableData = (params) => {
    if (this.state.search && this.state.search !== '') {
      params.search = this.state.search;
    }

    console.log('fetchOfflineSpecialistTableData(params)', params);

    getUserAndPatientOfflineSpecialistData(params)
      .then((responseBody) => {
        console.log(
          responseBody.data,
          'getUserAndPatientOfflineSpecialistData:responseBody.data'
        );

        const csvList = this.getCsvList(responseBody);

        // console.log(responseBody[1][0].totalHit);
        this.setState({
          data: responseBody.data,
          dataCsv: csvList,
          pages: 10,
          loading: false,
        });
        // return getAllDocOnCallDataCount(params);
        // console.log(this.state.pages);
      })
      .then((responseBody) => {
        this.setState({
          // pages: Math.ceil(responseBody.totalHits / this.state.pageSize),
          pages: 10,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  // Consultation View
  showConsultationView = (
    consultationId,
    consultationType = this.state.consultationType,
    userId = null
  ) => {
    this.setState({
      showConsultModalIsOpen: true,
      consultationModalConsultationId: consultationId,
      consultViewConsultationType: consultationType,
      currentRowUserId: userId,
    });
  };

  closeConsultationView = () => {
    this.setState(
      {
        showConsultModalIsOpen: false,
      },
      this.fetchUserAndPatientTableData
    );
  };
  // Open Book Consultation Modal
  showBookConsultation = () => {
    console.log('show consultation modal');
    this.setState({
      bookConsultModalIsOpen: true,
    });
  };
  closeBookConsultation = () => {
    this.setState(
      {
        bookConsultModalIsOpen: false,
      },
      this.fetchUserAndPatientTableData
    );
  };

  // Edit Consultation Modal
  editConsultOpenModal = (consultId) => {
    this.setState({ editConsultModalIsOpen: true, consultId: consultId });
  };

  editConsultCloseModal = () => {
    this.setState({ editConsultModalIsOpen: false });
  };

  setVipUsersFilter = () => {
    this.setState({
      onlyVipUsers: !this.state.onlyVipUsers,
    },
    this.fetchUserAndPatientTableData
    );
  }

  handleConsultationDateChange = (id, value) => {
    const { dispatch } = this.props;

    const filter = {};
    filter[id] = value;
    this.setState(
      {
        exFilters: {
          ...this.state.exFilters,
          consultationDate: {
            ...this.state.exFilters.consultationDate,
            ...filter,
          },
        },
      },
      this.fetchUserAndPatientTableData
    );

    console.log(id, value, 'handleConsultationDateChange');

    switch (id) {
      case 'startDate': {
        dispatch(setStartDateInRedux(value, window.location.pathname));
        break;
      }
      case 'endDate': {
        dispatch(setEndDateInRedux(value, window.location.pathname));
        break;
      }
      default:
        break;
    }
  };

  handleSponsorChange = (value, areAllSponsorsSelected) => {
    const { dispatch } = this.props;
    console.log({ value });
    this.setState(
      {
        exFilters: {
          ...this.state.exFilters,
          sponsorName: areAllSponsorsSelected ? '' : value,
        },
      },
      this.fetchUserAndPatientTableData
    );
    dispatch(
      setSponsorsInRedux(
        areAllSponsorsSelected ? '' : value,
        window.location.pathname
      )
    );
  };

  handleVerticalChange = (value) => {
    const { dispatch } = this.props;
    console.log('handleVerticalChange', { value });
    this.setState(
      {
        exFilters: {
          ...this.state.exFilters,
          verticalId: value,
        },
      },
      this.fetchUserAndPatientTableData
    );
    dispatch(setVerticalsInRedux(value, window.location.pathname));

    // any change here will make group-of as "All": Note: only sufficient for dropdown
    dispatch(
      setGroupOfInRedux(
        { value: 'all', label: 'All' },
        window.location.pathname
      )
    );
    this.setState({ groupOf: { value: 'all', label: 'All' } });
  };

  handleVerticalGroupByChange = (value) => {
    const { dispatch } = this.props;
    const groupOfValue = value;
    this.setState({ groupOf: groupOfValue });

    // Two changes here ->
    // 1) dropdown options change
    // 2) filter change

    console.log('groupName:value:before', value);
    if (value.value === 'all') {
      // include all
      value = this.verticalGroup.all;
      this.setState({ verticalFilterDisplay: 'block' });
    } else if (value.value === 'general') {
      value = this.verticalGroup.general;
      this.setState({ verticalFilterDisplay: 'none' });
    } else if (value.value === 'specialist') {
      this.setState({ verticalFilterDisplay: 'none' });
      value = this.verticalGroup.specialist;
    } else if (value.value === 'advisor') {
      this.setState({ verticalFilterDisplay: 'none' });
      value = this.verticalGroup.advisor;
    } else {
      value = [];
    }
    console.log('groupName:value:after', value);
    this.setState(
      {
        exFilters: {
          ...this.state.exFilters,
          verticalId: value,
        },
      },
      this.fetchUserAndPatientTableData
    );

    dispatch(setGroupOfInRedux(groupOfValue, window.location.pathname));
    dispatch(setVerticalsInRedux(value, window.location.pathname));
  };

  handleDoctorChange = (value, areAllDocsSelected) => {
    let doctorsFromRedux;

    switch (window.location.pathname) {
      case '/doc-on-call': {
        doctorsFromRedux = this.props.doctorsDocOnCall;
        break;
      }
      case '/specialist-consults': {
        doctorsFromRedux = this.props.doctorsSpecialistConsults;
        break;
      }
      case '/offline-sp': {
        doctorsFromRedux = this.props.doctorsOfflineSp;
        break;
      }
      case '/scheduled-consults': {
        doctorsFromRedux = this.props.doctorsScheduledConsults;
        break;
      }
      default:
        break;
    }

    // console.log('handleDoctorChange', typeof value, value);
    const { dispatch } = this.props;
    this.setState(
      {
        exFilters: {
          ...this.state.exFilters,
          doctors: areAllDocsSelected ? '' : value,
        },
      },
      this.fetchUserAndPatientTableData
    );
    // save the state in redux
    // value = [54,23]
    // dispatch(setDoctorsInRedux(value, window.location.pathname));
    dispatch(
      setDoctorsInRedux(
        {
          all:
            doctorsFromRedux && doctorsFromRedux.all
              ? doctorsFromRedux.all
              : value,
          selected: areAllDocsSelected ? '' : value,
        },
        window.location.pathname
      )
    );
  };

  clearAllFilters = () => {
    // This method is Not used currently

    const { dispatch } = this.props;
    dispatch(setVerticalsInRedux([], window.location.pathname));
    dispatch(setDoctorsInRedux([], window.location.pathname));
    dispatch(setSponsorsInRedux([], window.location.pathname));
    // this.fetchUserAndPatientTableData();
  };

  getGroupOfValue() {
    let prevValues;

    switch (window.location.pathname) {
      case '/doc-on-call': {
        prevValues = this.props.groupOfDocOnCall;
        break;
      }
      case '/specialist-consults': {
        prevValues = this.props.groupOfSpecialistConsults;
        break;
      }
      case '/offline-sp': {
        prevValues = this.props.groupOfOfflineSp;
        break;
      }
      case '/scheduled-consults': {
        prevValues = this.props.groupOfScheduledConsults;
        break;
      }
      default:
        prevValues = null;
        break;
    }

    if (prevValues) {
      // group-of value is present
      return prevValues;
    } else {
      return { value: 'all', label: 'All' };
    }
  }

  getStartDateValue() {
    let prevValues;

    switch (window.location.pathname) {
      case '/doc-on-call': {
        prevValues = this.props.startDateDocOnCall;
        break;
      }
      case '/specialist-consults': {
        prevValues = this.props.startDateSpecialistConsults;
        break;
      }
      case '/offline-sp': {
        prevValues = this.props.startDateOfflineSp;
        break;
      }
      case '/scheduled-consults': {
        prevValues = this.props.startDateScheduledConsults;
        break;
      }
      default:
        prevValues = null;
        break;
    }

    if (prevValues) {
      // value is present in redux state
      return prevValues;
    } else {
      return '';
    }
  }

  getEndDateValue() {
    let prevValues;

    switch (window.location.pathname) {
      case '/doc-on-call': {
        prevValues = this.props.endDateDocOnCall;
        break;
      }
      case '/specialist-consults': {
        prevValues = this.props.endDateSpecialistConsults;
        break;
      }
      case '/offline-sp': {
        prevValues = this.props.endDateOfflineSp;
        break;
      }
      case '/scheduled-consults': {
        prevValues = this.props.endDateScheduledConsults;
        break;
      }
      default:
        prevValues = null;
        break;
    }

    if (prevValues) {
      // value is present in redux state
      return prevValues;
    } else {
      return '';
    }
  }

  componentDidMount() {
    let verticalsFromRedux;
    let doctorsFromRedux;
    let sponsorsFromRedux;

    let groupOfFromRedux;
    let startDateFromRedux;
    let endDateFromRedux;

    let internalFromRedux;

    let pageIndexFromRedux;
    let pageSizeFromRedux;

    switch (window.location.pathname) {
      case '/doc-on-call': {
        verticalsFromRedux = this.props.verticalsDocOnCall;
        doctorsFromRedux = this.props.doctorsDocOnCall;
        sponsorsFromRedux = this.props.sponsorsDocOnCall;
        groupOfFromRedux = this.props.groupOfDocOnCall;
        startDateFromRedux = this.props.startDateDocOnCall;
        endDateFromRedux = this.props.endDateDocOnCall;

        internalFromRedux = this.props.internalDocOnCall;
        pageSizeFromRedux = this.props.pageSizeDocOnCall;
        pageIndexFromRedux = this.props.pageIndexDocOnCall;

        break;
      }
      case '/specialist-consults': {
        verticalsFromRedux = this.props.verticalsSpecialistConsults;
        doctorsFromRedux = this.props.doctorsSpecialistConsults;
        sponsorsFromRedux = this.props.sponsorsSpecialistConsults;
        groupOfFromRedux = this.props.groupOfSpecialistConsults;
        startDateFromRedux = this.props.startDateSpecialistConsults;
        endDateFromRedux = this.props.endDateSpecialistConsults;

        internalFromRedux = this.props.internalSpecialistConsults;
        pageSizeFromRedux = this.props.pageSizeSpecialistConsults;
        pageIndexFromRedux = this.props.pageIndexSpecialistConsults;

        break;
      }
      case '/offline-sp': {
        verticalsFromRedux = this.props.verticalsOfflineSp;
        doctorsFromRedux = this.props.doctorsOfflineSp;
        sponsorsFromRedux = this.props.sponsorsOfflineSp;
        groupOfFromRedux = this.props.groupOfOfflineSp;
        startDateFromRedux = this.props.startDateOfflineSp;
        endDateFromRedux = this.props.endDateOfflineSp;

        internalFromRedux = this.props.internalOfflineSp;
        pageSizeFromRedux = this.props.pageSizeOfflineSp;
        pageIndexFromRedux = this.props.pageIndexOfflineSp;

        break;
      }
      case '/scheduled-consults': {
        verticalsFromRedux = this.props.verticalsScheduledConsults;
        doctorsFromRedux = this.props.doctorsScheduledConsults;
        sponsorsFromRedux = this.props.sponsorsScheduledConsults;
        groupOfFromRedux = this.props.groupOfScheduledConsults;
        startDateFromRedux = this.props.startDateScheduledConsults;
        endDateFromRedux = this.props.endDateScheduledConsults;

        internalFromRedux = this.props.internalScheduledConsults;
        pageSizeFromRedux = this.props.pageSizeScheduledConsults;
        pageIndexFromRedux = this.props.pageIndexScheduledConsults;

        break;
      }
      default:
        break;
    }
    console.log(
      // groupOfFromRedux,
      // startDateFromRedux,
      // endDateFromRedux,
      // internalFromRedux,
      pageSizeFromRedux,
      pageIndexFromRedux,
      // verticalsFromRedux,
      // doctorsFromRedux,
      // sponsorsFromRedux,
      'ONMOUNT:VDS_REDUX'
    );

    if (
      (verticalsFromRedux && verticalsFromRedux.length > 0) ||
      (doctorsFromRedux &&
        doctorsFromRedux.all &&
        doctorsFromRedux.all.length > 0) ||
      (sponsorsFromRedux && sponsorsFromRedux.length > 0) ||
      internalFromRedux ||
      pageIndexFromRedux ||
      pageSizeFromRedux
    ) {

      console.log('ONMOUNT:VDS_REDUX:FOLLOWUP');
      this.setState(
        {
          filters: this.state.filters,
          exFilters: {
            ...this.state.exFilters,
            consultationDate: {
              ...this.state.exFilters.consultationDate,
              startDate: startDateFromRedux,
              endDate: endDateFromRedux,
            },
            verticalId: verticalsFromRedux,
            doctors:
              doctorsFromRedux && doctorsFromRedux.selected
                ? doctorsFromRedux.selected
                : '',
            sponsorName: sponsorsFromRedux,
            internalFilter: internalFromRedux,
            pageIndex: pageIndexFromRedux,
            pageSize: pageSizeFromRedux,
          },
        },
        () => {
          this.fetchUserAndPatientTableData();
        }
      );
    }

    // vertical group filter un-showing
    if (groupOfFromRedux && groupOfFromRedux.value !== 'all') {
      this.setState({ verticalFilterDisplay: 'none' });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let verticalsFromRedux;
    let doctorsFromRedux;
    let sponsorsFromRedux;

    let verticalsFromReduxPrev;
    let doctorsFromReduxPrev;
    let sponsorsFromReduxPrev;

    let groupOfFromRedux;
    let startDateFromRedux;
    let endDateFromRedux;

    let groupOfFromReduxPrev;
    let startDateFromReduxPrev;
    let endDateFromReduxPrev;

    let internalFromRedux;
    let internalFromReduxPrev;

    let pageIndexFromRedux;
    let pageIndexFromReduxPrev;

    let pageSizeFromRedux;
    let pageSizeFromReduxPrev;

    switch (window.location.pathname) {
      case '/doc-on-call': {
        verticalsFromRedux = this.props.verticalsDocOnCall;
        doctorsFromRedux = this.props.doctorsDocOnCall;
        sponsorsFromRedux = this.props.sponsorsDocOnCall;
        groupOfFromRedux = this.props.groupOfDocOnCall;
        startDateFromRedux = this.props.startDateDocOnCall;
        endDateFromRedux = this.props.endDateDocOnCall;
        internalFromRedux = this.props.internalDocOnCall;
        pageIndexFromRedux = this.props.pageIndexDocOnCall;
        pageSizeFromRedux = this.props.pageSizeDocOnCall;

        verticalsFromReduxPrev = prevProps.verticalsDocOnCall;
        doctorsFromReduxPrev = prevProps.doctorsDocOnCall;
        sponsorsFromReduxPrev = prevProps.sponsorsDocOnCall;
        groupOfFromReduxPrev = prevProps.groupOfDocOnCall;
        startDateFromReduxPrev = prevProps.startDateDocOnCall;
        endDateFromReduxPrev = prevProps.endDateDocOnCall;
        internalFromReduxPrev = prevProps.internalDocOnCall;
        pageIndexFromReduxPrev = prevProps.pageIndexDocOnCall;
        pageSizeFromReduxPrev = prevProps.pageSizeDocOnCall;

        break;
      }
      case '/specialist-consults': {
        verticalsFromRedux = this.props.verticalsSpecialistConsults;
        doctorsFromRedux = this.props.doctorsSpecialistConsults;
        sponsorsFromRedux = this.props.sponsorsSpecialistConsults;
        groupOfFromRedux = this.props.groupOfSpecialistConsults;
        startDateFromRedux = this.props.startDateSpecialistConsults;
        endDateFromRedux = this.props.endDateSpecialistConsults;
        internalFromRedux = this.props.internalSpecialistConsults;
        pageIndexFromRedux = this.props.pageIndexSpecialistConsults;
        pageSizeFromRedux = this.props.pageSizeSpecialistConsults;

        verticalsFromReduxPrev = prevProps.verticalsSpecialistConsults;
        doctorsFromReduxPrev = prevProps.doctorsSpecialistConsults;
        sponsorsFromReduxPrev = prevProps.sponsorsSpecialistConsults;
        groupOfFromReduxPrev = prevProps.groupOfSpecialistConsults;
        startDateFromReduxPrev = prevProps.startDateSpecialistConsults;
        endDateFromReduxPrev = prevProps.endDateSpecialistConsults;
        internalFromReduxPrev = prevProps.internalSpecialistConsults;
        pageIndexFromReduxPrev = prevProps.pageIndexSpecialistConsults;
        pageSizeFromReduxPrev = prevProps.pageSizeSpecialistConsults;

        break;
      }
      case '/offline-sp': {
        verticalsFromRedux = this.props.verticalsOfflineSp;
        doctorsFromRedux = this.props.doctorsOfflineSp;
        sponsorsFromRedux = this.props.sponsorsOfflineSp;
        groupOfFromRedux = this.props.groupOfOfflineSp;
        startDateFromRedux = this.props.startDateOfflineSp;
        endDateFromRedux = this.props.endDateOfflineSp;
        internalFromRedux = this.props.internalOfflineSp;
        pageIndexFromRedux = this.props.pageIndexOfflineSp;
        pageSizeFromRedux = this.props.pageSizeOfflineSp;

        verticalsFromReduxPrev = prevProps.verticalsOfflineSp;
        doctorsFromReduxPrev = prevProps.doctorsOfflineSp;
        sponsorsFromReduxPrev = prevProps.sponsorsOfflineSp;
        groupOfFromReduxPrev = prevProps.groupOfOfflineSp;
        startDateFromReduxPrev = prevProps.startDateOfflineSp;
        endDateFromReduxPrev = prevProps.endDateOfflineSp;
        internalFromReduxPrev = prevProps.internalOfflineSp;
        pageIndexFromReduxPrev = prevProps.pageIndexOfflineSp;
        pageSizeFromReduxPrev = prevProps.pageSizeOfflineSp;

        break;
      }
      case '/scheduled-consults': {
        verticalsFromRedux = this.props.verticalsScheduledConsults;
        doctorsFromRedux = this.props.doctorsScheduledConsults;
        sponsorsFromRedux = this.props.sponsorsScheduledConsults;
        groupOfFromRedux = this.props.groupOfScheduledConsults;
        startDateFromRedux = this.props.startDateScheduledConsults;
        endDateFromRedux = this.props.endDateScheduledConsults;
        internalFromRedux = this.props.internalScheduledConsults;
        pageIndexFromRedux = this.props.pageIndexScheduledConsults;
        pageSizeFromRedux = this.props.pageSizeScheduledConsults;

        verticalsFromReduxPrev = prevProps.verticalsScheduledConsults;
        doctorsFromReduxPrev = prevProps.doctorsScheduledConsults;
        sponsorsFromReduxPrev = prevProps.sponsorsScheduledConsults;
        groupOfFromReduxPrev = prevProps.groupOfScheduledConsults;
        startDateFromReduxPrev = prevProps.startDateScheduledConsults;
        endDateFromReduxPrev = prevProps.endDateScheduledConsults;
        internalFromReduxPrev = prevProps.internalScheduledConsults;
        pageIndexFromReduxPrev = prevProps.pageIndexScheduledConsults;
        pageSizeFromReduxPrev = prevProps.pageSizeScheduledConsults;

        break;
      }
      default:
        break;
    }

    console.log(
      verticalsFromReduxPrev,
      verticalsFromRedux,
      'prevProps31Aug:verticalsFromReduxPrev'
    );

    if (
      verticalsFromReduxPrev !== verticalsFromRedux ||
      doctorsFromReduxPrev !== doctorsFromRedux ||
      sponsorsFromReduxPrev !== sponsorsFromRedux ||
      internalFromReduxPrev !== internalFromRedux ||
      pageIndexFromReduxPrev !== pageIndexFromRedux ||
      pageSizeFromReduxPrev !== pageSizeFromRedux
    ) {

      this.setState(
        {
          exFilters: {
            filters: this.state.filters,
            ...this.state.exFilters,
            consultationDate: {
              ...this.state.exFilters.consultationDate,
              startDate: startDateFromRedux,
              endDate: endDateFromRedux,
            },
            verticalId: verticalsFromRedux,
            doctors:
              doctorsFromRedux && doctorsFromRedux.selected
                ? doctorsFromRedux.selected
                : '',
            sponsorName: sponsorsFromRedux,
            internalFilter: internalFromRedux,
            pageIndex: pageIndexFromReduxPrev,
            pageSize: pageSizeFromReduxPrev,
          },
        },
        () => {
          this.fetchUserAndPatientTableData();
        }
      );
    }
  }

  getInternalInitial = (key) => {
    let internalFromRedux;

    switch (window.location.pathname) {
      case '/doc-on-call': {
        internalFromRedux = this.props.internalDocOnCall;
        break;
      }
      case '/specialist-consults': {
        internalFromRedux = this.props.internalSpecialistConsults;
        break;
      }
      case '/offline-sp': {
        internalFromRedux = this.props.internalOfflineSp;
        break;
      }
      case '/scheduled-consults': {
        internalFromRedux = this.props.internalScheduledConsults;
        break;
      }
      default:
        break;
    }

    if (internalFromRedux && internalFromRedux[key]) {
      return internalFromRedux[key];
    } else {
      return null;
    }
  };

  getPageIndexInitial = () => {
    let pageIndexFromRedux;

    switch (window.location.pathname) {
      case '/doc-on-call': {
        pageIndexFromRedux = this.props.pageIndexDocOnCall;

        break;
      }
      case '/specialist-consults': {
        pageIndexFromRedux = this.props.pageIndexSpecialistConsults;

        break;
      }
      case '/offline-sp': {
        pageIndexFromRedux = this.props.pageIndexOfflineSp;
        break;
      }
      case '/scheduled-consults': {
        pageIndexFromRedux = this.props.pageIndexScheduledConsults;
        break;
      }
      default:
        break;
    }

    if (pageIndexFromRedux) {
      return pageIndexFromRedux;
    } else {
      return null;
    }
  };

  getPageSizeInitial = () => {
    let pageSizeFromRedux;

    switch (window.location.pathname) {
      case '/doc-on-call': {
        pageSizeFromRedux = this.props.pageSizeDocOnCall;
        break;
      }
      case '/specialist-consults': {
        pageSizeFromRedux = this.props.pageSizeSpecialistConsults;
        break;
      }
      case '/offline-sp': {
        pageSizeFromRedux = this.props.pageSizeOfflineSp;
        break;
      }
      case '/scheduled-consults': {
        pageSizeFromRedux = this.props.pageSizeScheduledConsults;
        break;
      }
      default:
        break;
    }

    if (pageSizeFromRedux) {
      return pageSizeFromRedux;
    } else {
      return null;
    }
  };

  setVerticalNull = () => {
    this.setState(
      {
        exFilters: {
          ...this.state.exFilters,
          verticalId: [],
        },
      },
      () => {
        this.fetchUserAndPatientTableData();
      }
    );
  };

  setDoctorNull = () => {
    this.setState(
      {
        exFilters: {
          ...this.state.exFilters,
          doctors: [],
        },
      },
      () => {
        this.fetchUserAndPatientTableData();
      }
    );
  };

  setSponsorNull = () => {
    this.setState(
      {
        exFilters: {
          ...this.state.exFilters,
          sponsorName: [],
        },
      },
      () => {
        this.fetchUserAndPatientTableData();
      }
    );
  };

  handleChange = (e) => {
    // console.log(e.key, 'searchLink1');
    // console.log(e.target.value, 'searchLink2');
    if (e.key === 'Enter') {
      console.log(e.target.value, 'searchLink');
      // if (e.target.value.replaceAll(' ', '').length >= 1) {
      this.debounceSearch(e.target.value);
      // }
    }
  };

  render() {
    const searchLink = Link.state(this, 'search').onChange((val) => {
      // console.log(val, 'searchLink');
    });

    return (
      <OuterComponent>
        <NavBar />
        <PaddedContainer>
          <div className="col-3">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <b>User and Patient Filter</b>
            <SearchBox
              valueLink={searchLink}
              type="text"
              placeholder="Search for Users and Patients (name, phone and email)     [Press Enter to Search]"
              className="form-control input-group p-2"
              onKeyPress={this.handleChange}
            />
          </div>
          <div className="col-1 text-center mt-2">
            <DownloadCsv
              jsonArray={this.state.dataCsv}
              fileName={this.state.consultationType}
              params={{
                draw: this.state.pageSize,
                page: this.state.pageIndex,
              }}
            />
          </div>
          <div className="col-3 mt-3">
            <div className="row text-center">
              <div className="col-4">
                <input
                  type="date"
                  value={this.state.exFilters.consultationDate.startDate}
                  onChange={(event) =>
                    this.handleConsultationDateChange(
                      'startDate',
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="col-4">
                <input
                  type="date"
                  value={this.state.exFilters.consultationDate.endDate}
                  onChange={(event) =>
                    this.handleConsultationDateChange(
                      'endDate',
                      event.target.value
                    )
                  }
                />
              </div>
            </div>
          </div>
        </PaddedContainer>
        <div
          style={{
            zIndex: 10,
          }}
        >
          <SubPaddedContainer>
            <div
              style={{
                marginRight: 10,
                minWidth: 400,
                maxWidth: 400,
                // zIndex: 11,
                backgroundColor: 'rgb(245,245,245)',
                padding: '10px',
                border: '1px solid rgb(230,230,230)',
                borderRadius: '6px',
              }}
            >
              <div className="row">
                <b className="col-3">Group Of</b>
                <div className="col-8">
                  <Select
                    onChange={this.handleVerticalGroupByChange}
                    options={this.group_names}
                    value={this.state.groupOf}
                  />
                </div>
                <b className="col-1"></b>
              </div>
              <br />
              {this.state.verticalFilterDisplay === 'none' ? (
                <span style={{ color: 'grey' }}>
                  (Select "All" as Group Of to View Sub Categories)
                </span>
              ) : null}
              <div style={{ display: this.state.verticalFilterDisplay }}>
                <b>Vertical Filter</b>
                <MultiVerticalFilter
                  onChangeValue={this.handleVerticalChange}
                  value={
                    this.state.exFilters
                      ? this.state.exFilters.verticalName
                      : ''
                  }
                  setVerticalNull={this.setVerticalNull}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <div
                style={{
                  marginRight: 10,
                  minWidth: 400,
                  maxWidth: 400,
                  // zIndex: 11,
                }}
              >
                <b>Doctor Filter</b>
                <MultiDoctorFilter
                  onChangeValue={this.handleDoctorChange}
                  value={
                    this.state.exFilters ? this.state.exFilters.doctors : ''
                  }
                  consultationType={this.state.consultationType}
                  setDoctorNull={this.setDoctorNull}
                />
              </div>
              <div
                style={{
                  marginRight: 20,
                  minWidth: 400,
                  maxWidth: 400,
                  // zIndex: 11,
                }}
              >
                <b>Sponsor Filter</b>
                <MultiSponsorFilter
                  onChangeValue={this.handleSponsorChange}
                  value={
                    this.state.exFilters ? this.state.exFilters.sponsorName : ''
                  }
                  setSponsorNull={this.setSponsorNull}
                />
              </div>
            </div>
            {this.state.consultationType !== 'scheduled-consults' ? (
              <div className="flex flex-col">
                <button
                  className="btn btn-primary m-4"
                  onClick={this.showBookConsultation}
                >
                  Book Consultation
                </button>
              </div>
            ) : (
              ''
            )}
            {
              this.state.consultationType === 'online-sp' ? (
                <ButtonGroup size={'sm'} style={{ 'margin-top': '1%', 'margin-right': '2rem' }}>
                <Button
                  style={{ height: '36px' }}
                  onClick={() => this.setVipUsersFilter()}
                >
                  Only VIP Users
                </Button>
                </ButtonGroup>
              ) : (
                ''
              )
            }
            {/* <div style={{ marginRight: 0, minWidth: 400 }}>
            <button className="btn btn-warning" onClick={this.clearAllFilters}>
              Clear All Filters
            </button>
          </div> */}
          </SubPaddedContainer>
        </div>
        <div
          style={{
            zIndex: 9,
          }}
        >
          <DocOnCallTable
            allowedColumns={
              this.state.consultationType === 'scheduled-consults'
                ? allowedCsvColumnsForScheduled
                : allowedCsvColumns
            }
            showConsultationView={this.showConsultationView}
            editConsultOpenModal={this.editConsultOpenModal}
            onFetchData={this.onFetchData}
            verticals={this.state.verticals}
            loading={this.state.loading}
            data={this.state.data}
            pageSize={this.state.pageSize}
            pages={this.state.pages}
            pageIndex={this.state.pageIndex}
            internalFilter={this.state.internalFilter}
            fetchUserAndPatientTableData={this.fetchUserAndPatientTableData}
            consultationType={this.state.consultationType}
          />
        </div>
        <EditConsultation
          modalIsOpen={this.state.editConsultModalIsOpen}
          openModal={this.editConsultOpenModal}
          closeModal={this.editConsultCloseModal}
          onComplete={this.onAction}
        />
        <ConsultationViewModal
          modalIsOpen={this.state.showConsultModalIsOpen}
          consultationId={this.state.consultationModalConsultationId}
          openModal={this.showConsultationView}
          closeModal={this.closeConsultationView}
          onComplete={this.onAction}
          consultationType={this.state.consultViewConsultationType}
          userId={this.state.currentRowUserId}
        />
        <BookConsultationModal
          modalIsOpen={this.state.bookConsultModalIsOpen}
          openModal={this.showBookConsultation}
          closeModal={this.closeBookConsultation}
          onComplete={this.onAction}
        />
      </OuterComponent>
    );
  }
}

const mapStateToProps = (state) => ({
  verticalsDocOnCall: state.filterHeader.verticalsDocOnCall,
  verticalsSpecialistConsults: state.filterHeader.verticalsSpecialistConsults,
  verticalsOfflineSp: state.filterHeader.verticalsOfflineSp,
  verticalsScheduledConsults: state.filterHeader.verticalsScheduledConsults,

  doctorsDocOnCall: state.filterHeader.doctorsDocOnCall,
  doctorsSpecialistConsults: state.filterHeader.doctorsSpecialistConsults,
  doctorsOfflineSp: state.filterHeader.doctorsOfflineSp,
  doctorsScheduledConsults: state.filterHeader.doctorsScheduledConsults,

  sponsorsDocOnCall: state.filterHeader.sponsorsDocOnCall,
  sponsorsSpecialistConsults: state.filterHeader.sponsorsSpecialistConsults,
  sponsorsOfflineSp: state.filterHeader.sponsorsOfflineSp,
  sponsorsScheduledConsults: state.filterHeader.sponsorsScheduledConsults,

  groupOfDocOnCall: state.filterHeader.groupOfDocOnCall,
  groupOfSpecialistConsults: state.filterHeader.groupOfSpecialistConsults,
  groupOfOfflineSp: state.filterHeader.groupOfOfflineSp,
  groupOfScheduledConsults: state.filterHeader.groupOfScheduledConsults,

  startDateDocOnCall: state.filterHeader.startDateDocOnCall,
  startDateSpecialistConsults: state.filterHeader.startDateSpecialistConsults,
  startDateOfflineSp: state.filterHeader.startDateOfflineSp,
  startDateScheduledConsults: state.filterHeader.startDateScheduledConsults,

  endDateDocOnCall: state.filterHeader.endDateDocOnCall,
  endDateSpecialistConsults: state.filterHeader.endDateSpecialistConsults,
  endDateOfflineSp: state.filterHeader.endDateOfflineSp,
  endDateScheduledConsults: state.filterHeader.endDateScheduledConsults,

  internalDocOnCall: state.filterHeader.internalDocOnCall,
  internalSpecialistConsults: state.filterHeader.internalSpecialistConsults,
  internalOfflineSp: state.filterHeader.internalOfflineSp,
  internalScheduledConsults: state.filterHeader.internalScheduledConsults,

  pageIndexDocOnCall: state.filterHeader.pageIndexDocOnCall,
  pageIndexSpecialistConsults: state.filterHeader.pageIndexSpecialistConsults,
  pageIndexOfflineSp: state.filterHeader.pageIndexOfflineSp,
  pageIndexScheduledConsults: state.filterHeader.pageIndexScheduledConsults,

  pageSizeDocOnCall: state.filterHeader.pageSizeDocOnCall,
  pageSizeSpecialistConsults: state.filterHeader.pageSizeSpecialistConsults,
  pageSizeOfflineSp: state.filterHeader.pageSizeOfflineSp,
  pageSizeScheduledConsults: state.filterHeader.pageSizeScheduledConsults,
});

export default connect(mapStateToProps)(loginCheck(withRouter(DoctorOnCall)));
