/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  getPolicyDetails,
  getStarhealthWalletBalance,
} from '../../services/api/policy-details';
import './NewPolicyDetails.css';
import styled from 'styled-components';
import {
  fetchWalletDetailsForUser,
  walletTransactions,
} from '../../services/api/opd-wallet-details';
import Modal from '../common/Modal';
import Select from 'react-select';

import LoadingComponent from '../common/LoadingComponent';
import {
  Button,
  Modal as ModalForWallet,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Input,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import WalletTransactionDetailsTable from '../customer-support/WalletTransactionDetailsTable';

const CardComponent = styled.div`
  padding: 10px;
  border: 1px solid #d5d5d5;
`;
const WalletBalanceComponent = styled.div`
  background: rgba(219, 235, 255, 0.5);
  border: 1px solid rgba(63, 140, 255, 0.2);
  color: #3f8cff;
  border-radius: 4px;
`;

export default function (props) {
  const [userInfo, setUserInfo] = useState({});
  const [relatives, setRelatives] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [activeUser, setActiveUser] = useState('');
  const [selectedUserPolicies, setSelectedUserPolicies] = useState([]);
  const [activePolicies, setActivePolicies] = useState([]);
  const [inactivePolicies, setInactivePolicies] = useState([]);
  const [relativesWalletDetails, setRelativesWalletDetails] = useState([]);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [starHealthPolicies, setStarHealthPolicies] = useState([]);
  const [showMorePolicyDetailsOfStar, setShowMorePolicyDetailsOfStar] =
    useState('');
  const [deonboardedPolicies, setDeonboardedPolicies] = useState([]);
  const [loader, setLoader] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [dependantOptions, setDepenantOptions] = useState([]);
  const [selectedDependant, setSelectedDependant] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [bookingAmount, setBookingAmount] = useState(100000);
  const [walletBalance, setWalletBalance] = useState(0);
  const [checkBalanceResponse, setCheckBalanceResponse] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(null);
  const [transactionsData, setTransactionsData] = useState([]);

  useEffect(() => {
    setLoader(true);
    fetchPolicyDetails();
  }, []);

  useEffect(() => {
    if (userInfo) {
      setActiveUser(userInfo);
    }
  }, [userInfo]);

  useEffect(() => {
    fetchWalletDetails(activeUser.userId, activeUser.relativeId);
  }, [activeUser]);

  const fetchWalletDetails = async (userId, relativeId) => {
    if (userId || relativeId) {
      const data = await fetchWalletDetailsForUser(userId, relativeId);
      console.log(data.response, '[dataResponse]');
      if (data.response) {
        setRelativesWalletDetails(data.response);
      } else if (data.message === 'warning') {
        setRelativesWalletDetails([]);
      }
    }
  };

  const bookingAmountHandler = (e) => {
    setCheckBalanceResponse(false);
    const filteredBookingAmount = e.target.value.replace(/\D/g, '');
    setBookingAmount(filteredBookingAmount);
  };

  const showModal = () => {
    setCheckBalanceResponse(false);
    setSelectedDependant('');
    setSelectedService('');
    setModalVisible(true);
  };

  const toggle = () => {
    setModalVisible(!modalVisible);
  };

  const selectService = (e) => {
    setCheckBalanceResponse(false);
    setSelectedService(e.value);
  };

  const selectDependant = (e) => {
    setCheckBalanceResponse(false);
    setSelectedDependant(e.value);
  };

  const checkBalance = async () => {
    setLoading(true);
    const { policyNumber, healthId } = selectedDependant;
    const serviceType = selectedService;
    const amount = bookingAmount;
    const params = {
      policyNumber,
      healthId,
      serviceType,
      bookingAmount: amount,
    };
    const balance = await getStarhealthWalletBalance(params);
    if (balance.message === 'success') {
      setWalletBalance(balance.walletBalance.cashlessEligibleAmount);
    }
    setLoading(false);
    setCheckBalanceResponse(true);
  };

  useEffect(() => {
    if (
      activeUser &&
      Object.keys(activeUser).length != 0 &&
      Object.getPrototypeOf(activeUser) === Object.prototype
    ) {
      if (!activeUser.relation && policies.length > 0) {
        let data = policies.map((item) =>
          item.holderId || (item.userId && !item.dependentId) ? item : null
        );
        data = data.filter(function (el) {
          return el != null;
        });
        setSelectedUserPolicies(data);
      } else if (activeUser.relation && policies.length > 0) {
        let data = policies.map((item) => {
          if (
            (item.holderId || (item.userId && !item.dependentId)) &&
            item.dependants.length > 0
          ) {
            let dependentData = item.dependants.map((ele) => {
              if (usermatch(ele.dependentName, ele.dependentGender)) {
                return item;
              }
              return null;
            });
            dependentData = dependentData.find(function (el) {
              return el != null;
            });
            return dependentData;
          } else if (item.dependentId) {
            if (usermatch(item.dependentName, item.dependentGender)) {
              return item;
            }
            return null;
          } else {
            return null;
          }
        });
        data = data.filter(function (el) {
          return el != null;
        });
        setSelectedUserPolicies(data);
      }
    }
    selectedDependant && selectedService && bookingAmount
      ? setIsDisabled(false)
      : setIsDisabled(true);
  }, [activeUser, policies, selectedDependant, selectedService, bookingAmount]);

  const usermatch = (name, gender) => {
    const newname = name.replace(/\s/g, '').toLowerCase();
    const newGender = gender.toLowerCase();
    let newMatch = `${newname}${newGender}`;
    let activeUserMatch = `${activeUser.name
      .replace(/\s/g, '')
      .toLowerCase()}${activeUser.gender.toLowerCase()}`;
    if (newMatch == activeUserMatch) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const activePolicy = selectedUserPolicies.filter((item) => item.active);
    const inactivePolicy = selectedUserPolicies.filter(
      (item) => item.active == 0
    );
    let deonboardedPolicy = selectedUserPolicies.map((items) => {
      if (items && items.dependants && items.dependants.length) {
        const findUser = items.dependants.filter(
          (item) =>
            item.deonboardedOn != null && item.dependentName == activeUser.name
        );
        console.log(findUser, 'findUser');
        if (findUser.length > 0) {
          return items;
        } else {
          return null;
        }
      }
      return null;
    });
    deonboardedPolicy = deonboardedPolicy.filter(function (el) {
      return el != null;
    });
    setDeonboardedPolicies(deonboardedPolicy);
    setActivePolicies(activePolicy);
    setInactivePolicies(inactivePolicy);
  }, [selectedUserPolicies]);

  const fetchPolicyDetails = async () => {
    const params = {
      userId: props.userId,
    };
    const result = await getPolicyDetails(params);
    if (result.data) {
      let { userInfo, policies, relatives } = result.data;
      relatives = relatives.map((relative) => {
        return { ...relative, userId: userInfo.userId };
      });
      setActiveUser(userInfo);
      setUserInfo(userInfo);
      setRelatives(relatives);
      setPolicies(policies);
    }
    setLoader(false);
    setStarHealthPolicies(result.starHealthPolicies);
  };

  const showStarHealthPolicies = () => {
    return starHealthPolicies.map((policy, index) => {
      return (
        <div
          className="flex flex-row w-full"
          style={{
            border: '1px solid grey',
            borderRadius: '8px',
            padding: '6px',
            margin: '8px 0px',
            background: 'rgba(0,0,0,.03)',
          }}
        >
          <div className="flex flex-col w-full">
            <div className="flex flex-row my-6px w-full">
              <div>
                <b>Policy Name:</b> {policy.productName}
              </div>
            </div>
            <div className="flex flex-row my-6px w-full">
              <div>
                <b>Policy Number:</b> {policy.policyNumber}{' '}
              </div>
            </div>
            <div className="flex flex-row my-6px w-full">
              <div>
                <b>Policy Star Date:</b> {policy.policyStartDate}
              </div>
            </div>
            <div className="flex flex-row my-6px w-full">
              <div>
                <b>Policy End Date:</b> {policy.policyEndDate}
              </div>
            </div>
            {showMorePolicyDetailsOfStar === index && (
              <div className="flex flex-col my-6px w-full">
                {showStarPolicyDependentDetails(policy.insuredDetails)}
              </div>
            )}
            <div
              className="w-full flex flex-row"
              style={{
                justifyContent: 'flex-start',
                fontSize: '12px',
                fontFamily: 'Montserrat',
              }}
            >
              <div
                className="flex flex-row ml-0 m-2 items-center justify-center"
                style={{
                  background: '#17a2b8',
                  color: 'white',
                  borderRadius: '4px',
                  padding: '4px 8px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  const dependentOptions =
                    policy.insuredDetails && policy.insuredDetails.length
                      ? policy.insuredDetails.map((dep) => ({
                          label: dep.name,
                          value: dep,
                        }))
                      : [];
                  setDepenantOptions(dependentOptions);
                  setSelectedDependant('');
                  showModal();
                }}
              >
                Check Wallet Balance
              </div>
              <div
                className="flex flex-row m-2 items-center justify-center"
                style={{
                  background: '#17a2b8',
                  color: 'white',
                  borderRadius: '4px',
                  padding: '4px 8px',
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
                onClick={() => {
                  let value = index;
                  if (showMorePolicyDetailsOfStar === index) {
                    value = null;
                  }
                  setShowMorePolicyDetailsOfStar(value);
                }}
              >
                {showMorePolicyDetailsOfStar === index
                  ? 'Less Details'
                  : 'More Details'}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const showStarPolicyDependentDetails = (dependents) => {
    return dependents.map((dep, index) => {
      return (
        <div className="mt-6px">
          <b>Dependent {index + 1}</b>
          <div className="flex flex-row my-6px w-full">
            <div>
              <b>Name:</b> {dep.name}
            </div>
          </div>
          <div className="flex flex-row my-6px w-full">
            <div>
              <b>Gender: </b> {dep.gender}{' '}
            </div>
          </div>
          <div className="flex flex-row my-6px w-full">
            <div>
              <b>Patient-Id: </b> {dep.patientId}
            </div>
          </div>
          <div className="flex flex-row my-6px w-full">
            <div>
              <b>Relative-Id:</b> {dep.relativeId}
            </div>
          </div>
          <div className="flex flex-row my-6px w-full">
            <div>
              <b>Health-Id:</b> {dep.healthId}
            </div>
          </div>
        </div>
      );
    });
  };

  const toggleDropdown = (policyId, primaryWalletId) => {
    if (isDropdownOpen === policyId) {
      setIsDropdownOpen(false);
      setTransactionsData([]);
    } else {
      setIsDropdownOpen(policyId);
      walletTransactions(null, null, primaryWalletId)
        .then((transactionResponse) => {
          console.log({ transactionResponse }, '[transactionResponse]');
          if (transactionResponse.message === 'success') {
            setTransactionsData(transactionResponse.response);
          }
        })
        .catch((err) => {
          console.log({ err }, '[transactionResponse][err]');
        });
    }
  };

  let firstUsername =
    (userInfo?.userName && userInfo?.userName.split(' ')[0]) || '';
  let middleUsername =
    (userInfo?.userName && userInfo?.userName.split(' ')[1]) || '';
  let lastUsername =
    (userInfo?.userName && userInfo?.userName.split(' ')[2]) || '';
  firstUsername =
    firstUsername && firstUsername.length > 12
      ? firstUsername.substring(0, 12) + '...'
      : firstUsername;
  middleUsername =
    middleUsername && middleUsername.length > 12
      ? middleUsername.substring(0, 12) + '...'
      : middleUsername;
  lastUsername =
    lastUsername && lastUsername.length > 12
      ? lastUsername.substring(0, 12) + '...'
      : lastUsername;
  return (
    <>
      <div className="container card">
        <div className="row mt-3 mb-3">
          {loader ? (
            <LoadingComponent />
          ) : (
            <div className="col-7">
              <label>
                <b>Policies</b>
              </label>
              <CardComponent>
                <div style={{ fontWeight: 600, fontSize: '20px' }}>
                  {activeUser.relativeId
                    ? activeUser.name
                    : activeUser.userName}
                </div>
                <div>
                  <h6 className="decorated">
                    <span>Active Policy</span>
                  </h6>
                  {activePolicies.length > 0 ? (
                    activePolicies.map((item, index) => {
                      return (
                        <>
                          <Card style={{ backgroundColor: '#F6F4FF' }}>
                            <CardHeader
                              style={{ color: '#714FFF' }}
                              onClick={() =>
                                toggleDropdown(
                                  item.policyId,
                                  item.primaryWalletId
                                )
                              }
                            >
                              {item.vendorName} ({item.policyName})
                            </CardHeader>
                            {isDropdownOpen === item.policyId && (
                              <CardBody>
                                <div className="flex flex-col p-2">
                                  <div className="flex justify-between">
                                    <p>Policy Duration: </p>
                                    {item.startDate ? (
                                      <p>
                                        {item.startDate} - {item.endDate}
                                      </p>
                                    ) : (
                                      <p>No duration available</p>
                                    )}
                                  </div>
                                  <div className="flex justify-between">
                                    <p>CAP Consults :</p>
                                    <p>{item.capConsults}</p>
                                  </div>
                                  <div className="flex justify-between">
                                    <p>Labs Cap Per Prescription : </p>
                                    <p>{item.labsCapPerPrescription}</p>
                                  </div>
                                  <div className="flex justify-between">
                                    <p>Max Capping per prescription : </p>
                                    <p>{item.labsCapPerTest}</p>
                                  </div>
                                  <div className="flex justify-between">
                                    <p>CAP Consults :</p>{' '}
                                    <p>{item.capConsults}</p>
                                  </div>
                                  <div className="flex justify-between">
                                    <p>Master Policy no. :</p>{' '}
                                    <p> {item.masterPolicyNumber}</p>
                                  </div>
                                  <div className="decorated border-top mb-4 mt-2">
                                    <div
                                      className="font-weight-bold text-left mb-2"
                                      style={{ color: '#714FFF' }}
                                    >
                                      Policy Wallet
                                    </div>

                                    {relativesWalletDetails &&
                                      relativesWalletDetails.map(
                                        (wallet, index) => {
                                          return (
                                            <div>
                                              <div className="flex justify-between">
                                                <p className="font-weight-bold">
                                                  Primary Wallet Balance:{' '}
                                                </p>
                                                <p>
                                                  {
                                                    wallet.response[0]
                                                      .primaryAvailableBalance
                                                  }{' '}
                                                  /{' '}
                                                  {
                                                    wallet.response[0]
                                                      .primaryTotalBalance
                                                  }
                                                </p>
                                              </div>
                                              <div key={index}>
                                                {wallet.response.length > 0 &&
                                                  wallet.response
                                                    .filter((ele) => {
                                                      return (
                                                        ele.policyId ===
                                                        item.policyId
                                                      );
                                                    })
                                                    .map(
                                                      (
                                                        eleGroup,
                                                        innerIndex
                                                      ) => {
                                                        let balanceDisplay = '';
                                                        if (
                                                          eleGroup.availableBalance ===
                                                            10000000 ||
                                                          eleGroup.totalBalance ===
                                                            10000000
                                                        ) {
                                                          balanceDisplay =
                                                            'Unlimited';
                                                        } else {
                                                          balanceDisplay = `${eleGroup.availableBalance}/${eleGroup.totalBalance}`;
                                                        }
                                                        return (
                                                          <div
                                                            key={innerIndex}
                                                            className="flex justify-between"
                                                          >
                                                            <p className="font-weight-bold">
                                                              {eleGroup.serviceKeyArray.join(
                                                                ', '
                                                              )}
                                                              :
                                                            </p>
                                                            <p>
                                                              {balanceDisplay}
                                                            </p>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                              </CardBody>
                            )}
                          </Card>
                          <hr />
                        </>
                      );
                    })
                  ) : (
                    <>
                      <h6>No Policy Found</h6>
                      <hr />
                    </>
                  )}
                </div>
                <div>
                  <h6 className="decorated" style={{ color: '#D53815' }}>
                    <span>Past Policy</span>
                  </h6>
                  {inactivePolicies.length > 0 ? (
                    inactivePolicies.map((item, index) => {
                      console.log(item, '[inactivePolicies]');
                      return (
                        <>
                          <Card style={{ backgroundColor: '#F6F4FF' }}>
                            <CardHeader
                              style={{ color: '#714FFF' }}
                              onClick={() =>
                                toggleDropdown(
                                  item.policyId,
                                  item.primaryWalletId
                                )
                              }
                            >
                              <p>
                                {item.vendorName} ({item.policyName})
                              </p>
                            </CardHeader>
                            {isDropdownOpen === item.policyId && (
                              <CardBody>
                                <div className="flex flex-col p-2">
                                  <div className="flex justify-between">
                                    <p>Policy Duration: </p>
                                    {item.startDate ? (
                                      <p>
                                        {item.startDate} - {item.endDate}
                                      </p>
                                    ) : (
                                      <p>No duration available</p>
                                    )}
                                  </div>
                                  <div className="flex justify-between">
                                    <p>CAP Consults :</p>
                                    <p>{item.capConsults}</p>
                                  </div>
                                  <div className="flex justify-between">
                                    <p>Labs Cap Per Prescription : </p>
                                    <p>{item.labsCapPerPrescription}</p>
                                  </div>
                                  <div className="flex justify-between">
                                    <p>Max Capping per prescription : </p>
                                    <p>{item.labsCapPerTest}</p>
                                  </div>
                                  <div className="flex justify-between">
                                    <p>CAP Consults :</p>{' '}
                                    <p>{item.capConsults}</p>
                                  </div>
                                  <div className="flex justify-between">
                                    <p>Master Policy no. :</p>{' '}
                                    <p> {item.masterPolicyNumber}</p>
                                  </div>
                                  <div className="decorated border-top mb-4 mt-2">
                                    <div
                                      className="font-weight-bold text-left mb-2"
                                      style={{ color: '#714FFF' }}
                                    >
                                      Policy Wallet
                                    </div>
                                    {relativesWalletDetails &&
                                      relativesWalletDetails.map(
                                        (wallet, index) => {
                                          return (
                                            <div>
                                              <div className="flex justify-between">
                                                <p className="font-weight-bold">
                                                  Primary Wallet Balance:{' '}
                                                </p>

                                                <p>
                                                  {
                                                    relativesWalletDetails[0]
                                                      .response[0]
                                                      .primaryAvailableBalance
                                                  }{' '}
                                                  /{' '}
                                                  {
                                                    relativesWalletDetails[0]
                                                      .response[0]
                                                      .primaryTotalBalance
                                                  }
                                                </p>
                                              </div>
                                              <div key={index}>
                                                {wallet.response.length > 0 &&
                                                  wallet.response
                                                    .filter((ele) => {
                                                      return (
                                                        ele.policyId ===
                                                        item.policyId
                                                      );
                                                    })
                                                    .map(
                                                      (
                                                        eleGroup,
                                                        innerIndex
                                                      ) => {
                                                        let balanceDisplay = '';
                                                        if (
                                                          eleGroup.availableBalance ===
                                                            10000000 ||
                                                          eleGroup.totalBalance ===
                                                            10000000
                                                        ) {
                                                          balanceDisplay =
                                                            'Unlimited';
                                                        } else {
                                                          balanceDisplay = `${eleGroup.availableBalance}/${eleGroup.totalBalance}`;
                                                        }
                                                        return (
                                                          <div
                                                            key={innerIndex}
                                                            className="flex justify-between"
                                                          >
                                                            <p className="font-weight-bold">
                                                              {eleGroup.serviceKeyArray.join(
                                                                ', '
                                                              )}
                                                              :
                                                            </p>
                                                            <p>
                                                              {balanceDisplay}
                                                            </p>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                              </CardBody>
                            )}
                          </Card>
                          {/* 
                            {deonboardedPolicies.length > 0 &&
                              deonboardedPolicies.map((item1) => {
                                const findUser = item1.dependants.find(
                                  (item2) =>
                                    item2.deonboardedOn != null &&
                                    item2.dependentName == activeUser.name
                                );
                                return item1.policyId == item.policyId &&
                                  item1.startDate == item.startDate &&
                                  findUser ? (
                                  <p className="removedOn">
                                    Removed On : {findUser.deonboardedOn}
                                  </p>
                                ) : (
                                  <p></p>
                                );
                              })}
                           */}
                          <hr />
                        </>
                      );
                    })
                  ) : (
                    <>
                      <h6>No Policy Found</h6>
                      <hr />
                    </>
                  )}
                </div>
              </CardComponent>
            </div>
          )}
          {loader ? (
            <LoadingComponent />
          ) : (
            <div className="col-2">
              <label
                style={{
                  width: 'max-content',
                }}
              >
                <b>User and Relatives Details</b>
              </label>
              <table className="table2-container">
                <thead>
                  <tr>
                    <th>Members</th>
                    <th>Relation</th>
                    <th>DOB</th>
                    <th>Phone No.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        maxWidth: '90px',
                      }}
                    >
                      <button
                        className="username"
                        onClick={() => setActiveUser(userInfo)}
                        title={userInfo.userName}
                      >
                        {firstUsername +
                          ' ' +
                          middleUsername +
                          ' ' +
                          lastUsername}
                      </button>
                    </td>
                    <td className="userDetails">{'Self'}</td>
                    <td className="userDetails">{userInfo.userDob}</td>
                    <td className="userDetails">
                      {userInfo.userPhone ? userInfo.userPhone : 'Not-Added'}
                    </td>
                  </tr>
                  {relatives.map((item, index) => {
                    let firstname = (item && item.name.split(' ')[0]) || '';
                    let middleName = (item && item.name.split(' ')[1]) || '';
                    let lastname = (item && item.name.split(' ')[2]) || '';

                    firstname =
                      firstname && firstname.length > 12
                        ? firstname.substring(0, 12) + '...'
                        : firstname;
                    middleName =
                      middleName && middleName.length > 12
                        ? middleName.substring(0, 12) + '...'
                        : middleName;
                    lastname =
                      lastname && lastname.length > 12
                        ? lastname.substring(0, 12) + '...'
                        : lastname;

                    return (
                      <tr key={index}>
                        <td
                          style={{
                            maxWidth: '90px',
                          }}
                        >
                          <button
                            className="username"
                            onClick={() => setActiveUser(item)}
                            title={item.name}
                          >
                            {firstname + ' ' + middleName + ' ' + lastname}
                          </button>
                        </td>
                        <td className="userDetails">{item.relation}</td>
                        <td className="userDetails">{item.dob}</td>
                        <td className="userDetails">
                          {item.phone ? item.phone : 'Not-Added'}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div style={{ width: '400px', marginTop: '20px' }}>
                {starHealthPolicies.length > 0 && (
                  <>
                    <label>
                      <b>Star Health Policies</b>
                    </label>
                    <div className="w-full">{showStarHealthPolicies()}</div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="row mt-2">
          <div className="ml-2">
            {transactionsData.length ? (
              <WalletTransactionDetailsTable data={transactionsData} />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <ModalForWallet
        isOpen={modalVisible}
        toggle={toggle}
        className="text-body-l font-semibold"
        style={{ fontFamily: 'Montserrat', maxWidth: '700px' }}
      >
        <ModalHeader toggle={toggle}>
          <p style={{ fontWeight: '800', margin: 0, padding: 0 }}>
            Check Wallet Balance
          </p>
        </ModalHeader>
        <ModalBody className="pb-0">
          <form>
            <FormGroup>
              <Row className="flex items-center">
                <Col className="col-3 flex items-center">
                  <Label className="m-0">Select Dependant:</Label>
                </Col>
                <Col>
                  <Select
                    isSearchable={true}
                    placeholder="Select Dependant"
                    onChange={selectDependant}
                    options={dependantOptions}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row className="flex items-center">
                <Col className="col-3 flex items-center">
                  <Label className="m-0">Select Benefits:</Label>
                </Col>
                <Col>
                  <Select
                    isSearchable={true}
                    placeholder="Select Benefits"
                    onChange={selectService}
                    options={[
                      { label: 'CONSULTATION', value: 'CONSULTATION' },
                      { label: 'DIAGNOSTICS', value: 'DIAGNOSTICS' },
                      { label: 'MEDICINE', value: 'MEDICINE' },
                      { label: 'PHYSIOTHERAPHY', value: 'PHYSIOTHERAPHY' },
                    ]}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row className="flex items-center">
                <Col className="col-3 flex items-center">
                  <Label className="m-0">Booking Amount</Label>
                </Col>
                <Col>
                  <Input
                    className="form-control"
                    style={{ color: 'black', fontWeight: '400' }}
                    onChange={bookingAmountHandler}
                    value={`रु ${bookingAmount}`}
                  />
                </Col>
              </Row>
            </FormGroup>
          </form>
        </ModalBody>
        <ModalFooter className="border-0 pt-0">
          {loading ? (
            <LoadingComponent />
          ) : checkBalanceResponse ? (
            <WalletBalanceComponent className="flex justify-between items-center w-full p-2 m-2 h-11">
              <div style={{ 'font-weight': 'bold' }}>
                Total Wallet Balance:{' '}
              </div>
              <div style={{ 'font-weight': 'bold' }}>रु {walletBalance} </div>
            </WalletBalanceComponent>
          ) : (
            <Button
              color="primary"
              onClick={checkBalance}
              disabled={isDisabled}
            >
              Check Balance
            </Button>
          )}
        </ModalFooter>
      </ModalForWallet>
      {/* <Modal
        header={
          <div className="font-weight-bold">
            Wallet Details For{' '}
            {activeUser.relativeId ? activeUser.name : activeUser.userName}
          </div>
        }
        isOpen={showWalletModal}
      >
        {relativesWalletDetails.length > 0 ? (
          <div className="container-fluid">
            {relativesWalletDetails.map((wallet, index) => {
              return (
                <div className="row">
                  {wallet.response.length > 0 && (
                    <>
                      <div className="col-md-9 col-md-push-3">
                        <h5 className="font-weight-bold">Primary Wallet:</h5>
                      </div>
                      <div className="col-md-3 col-md-pull-9">
                        <h6>
                          {`${wallet.response[0].primaryAvailableBalance}/${wallet.response[0].primaryTotalBalance}`}
                        </h6>
                      </div>
                    </>
                  )}
                  {wallet.response.length > 0 &&
                    wallet.response.map((ele) => {
                      return (
                        <>
                          <div className="col-md-9 col-md-push-3">
                            <h5 className="font-weight-bold">
                              {ele.serviceKeyArray.join(', ')}:
                            </h5>
                          </div>
                          <div className="col-md-3 col-md-pull-9">
                            <h6>
                              {`${ele.availableBalance}/${ele.totalBalance}`}
                            </h6>
                          </div>
                        </>
                      );
                    })}
                </div>
              );
            })}
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-block btn-danger"
                  onClick={() => setShowWalletModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row">
              <p>No Wallet Found</p>
            </div>
            <div className="row">
              <button
                className="btn btn-block btn-danger"
                onClick={() => setShowWalletModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </Modal> */}
    </>
  );
}
