import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import moment from 'moment';
import { TimePicker } from 'antd';
import 'antd/dist/antd.css';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { BoldText, RegularText} from '../common/Text';
import { FaEdit } from 'react-icons/fa';
import { getDoctorSlots } from '../../services/api/offline-consult';

const SectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.625rem;
`;

const SectionHeader = styled(BoldText)`
  font-size: 1.2rem;
  color: #4b4f5b;
`;

const SectionBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.625rem;
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 0.25rem;
`;

const DetailName = styled.div`
  font-size: 1rem;
  width: ${(props) => (props.fullWidth ? '20rem' : '8rem')};
  color: #4c4c4c;
`;
const Warning = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.625rem;
  padding-top: 1rem;
  color: #FFAF3C;
`;

const DetailText = styled(RegularText)`
  flex: 1;
  font-size: 1rem;
  color: ${(props) => props.color || '#787778'};
  margin-left: 0.25rem;
`;
class OfflineModalConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slotvalue: null,
      value: '20161212',
      slotMeredian: 'AM',
      loader: false,
      requestData: {},
      appDate: null,
      appTimeRange: null,
      startTime: '',
      endTime: '',
      timeformat: 'hh:mm:00 a',
      dateKey: '20161212',
      slotType: 'fixed',
      startAppTime:null,
      endAppTime:null,
      doctorSlots: null,
      warning: '',
      showDoctorNewSlots: false,

    };
  }

  componentDidUpdate(prevProps) {
    if(this.props.showEditDoctorSlots !== prevProps.showEditDoctorSlots){
      this.getDoctorSlots();
    }
    if (
      this.props.requestDataForConfirm &&
      prevProps.requestDataForConfirm !== this.props.requestDataForConfirm
    ) {
      this.getDoctorSlots();
      let apppointMentDate = new Date(
        this.props.requestDataForConfirm.uAppointmentDate
      );
      console.log(apppointMentDate);
      let formattedDate =
        apppointMentDate && apppointMentDate.toISOString().split('T')[0];
      let appointmentTime =
        this.props.requestDataForConfirm.uAppointmentDate.split(' ')[1];
      console.log(appointmentTime);
      let startTime = appointmentTime;

      if (this.props.requestDataForConfirm.appointmentDateAfterReschedule) {
        startTime = this.props.requestDataForConfirm.appointmentDateAfterReschedule;
      }
      if (this.props.requestDataForConfirm.appointmentDateKeyAfterReschedule) {
        formattedDate = this.props.requestDataForConfirm.appointmentDateKeyAfterReschedule;
      }

      const timeString = startTime;
      const date = new Date(`1970-01-01T${timeString}`);
      let [startHour, startMinute] = startTime ? startTime.split(':') : [];
      date.setHours(date.getHours() + 3);
      // date.setMinutes(date.getMinutes() + 30);

      let endTime = date.toTimeString().slice(0, 8);
      if(startHour>20){
        endTime = "24:00:00";
      }

      const dateKey = moment(formattedDate, 'YYYY-MM-DD').format('YYYYMMDD');
      console.log(startTime, endTime, 'sshejs123',formattedDate,this.props.requestDataForConfirm)
      this.setState({ dateKey });
      this.setState({ value: formattedDate });
      this.setState({
        requestData: this.props.requestDataForConfirm,
        appDate: formattedDate,
        appTimeRange: appointmentTime,
        startTime: startTime,
        endTime: endTime,
        doctorSlots: null
      });
    }
  }

  checkWhetherApptDateWithinSlots = (date, valueModified = false) => {
    let isWithinSlots = false;
    const apptDate = new Date(date);
    const apptDOW = apptDate.getDay()+1;
    const apptTime = moment(apptDate).format('HH:mm:ss');
    console.log('checkWhetherApptDateWithinSlots', { date, apptDate, apptDOW, apptTime});
    const apptFormatDate = moment(date).format('YYYY-MM-DD')
    const correctSlots = !this.state.showDoctorNewSlots ? this.state.doctorSlots.filter(slot => slot.dow === apptDOW) : this.state.doctorSlots.filter(slot => slot.formattedDateKey === apptFormatDate);
    if(!this.state.showDoctorNewSlots){
    for(let slot of correctSlots) {
      for(let slotValue of slot.values) {
        const startTime = moment(slotValue.startTime, 'hh:mm A').format('HH:mm:ss');
        const endTime = moment(slotValue.endTime, 'hh:mm A').format('HH:mm:ss');
        if(apptTime >= startTime && apptTime <= endTime)
          isWithinSlots = true;
      }
    }}
    else{
      if(correctSlots[0] && correctSlots[0].slots){
        for(let slotValue of correctSlots[0].slots) {
        const startTime = moment(slotValue.slotKey, 'hh:mm A').format('HH:mm:ss');
        const endTime = moment(slotValue.slotKeyEndTime, 'hh:mm A').format('HH:mm:ss');
        if(apptTime >= startTime && apptTime <= endTime){
          isWithinSlots = true;
        }
      }
    }
    }
    this.setState({
        warning: !isWithinSlots ? 'The selected Appointment Date/Time is not within the Available Doctor Slots, edit the slots or select different date/time': ''
    })
    if(!isWithinSlots && valueModified) {
      toast.error('The selected Appointment Date/Time is not within the Available Doctor Slots, edit the slots or select different date/time');
      this.setState({doctorSlots:null});
      if(!this.state.showDoctorNewSlots){
      this.props.showEditDoctorSlotsModal(this.state.requestData.doctorId)
      }
    }
    return isWithinSlots;
  }

  getDoctorSlots = () => {
    const { requestId } = this.props.requestDataForConfirm;

    getDoctorSlots({ requestId })
      .then((res) => {
        console.log({ slots: res.data }, 'getDoctorSlots');

        if(res.data.showSlot){
          this.setState({
            slotType: 'walk-in',
            showDoctorNewSlots: res.data.showSlot
          });  
        }
        this.setState({
          doctorSlots: res.data.slots,
        });
        this.checkWhetherApptDateWithinSlots(this.props.requestDataForConfirm.uAppointmentDate);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  handleChange = (e) => {
    let date = moment(e.target.value, 'YYYY-MM-DD').format('YYYYMMDD');

    this.setState({ value: date });
  };
  slothandleChange = (e, time) => {
    if (time) {
      console.log(time.toUpperCase(), 'a slot change');
      this.setState({ slotvalue: time.toUpperCase() });
      this.checkWhetherApptDateWithinSlots(`${this.state.value} ${time.toUpperCase()}`, true);
    }
    //console.log(this.setState.slotValue,'a slot change')
  };

  handleStartAppTime = (e, time) => {
    if (time) {
      let newTime = time.substring(0,3) + time.substring(3, 5) + ' ' + time.substring(9);
      this.setState({
        startAppTime: newTime.toUpperCase(),
        slotvalue : time.toUpperCase()
       });
       this.checkWhetherApptDateWithinSlots(`${this.state.value} ${time.toUpperCase()}`, true);
    }
  };

  handleEndAppTime = (e, time) => {
    if (time) {
      let newTime = time.substring(0,3) + time.substring(3, 5) + ' ' + time.substring(9);
      this.setState({ endAppTime: newTime.toUpperCase() });
    }
  };

  handleWalkIn = (timeString) => {
    const [hourString, minute, seconds] = timeString.split(':');
    let hour = +hourString % 24;
    let time = (hour % 12 || 12) + ':' + minute + ':' + seconds + (hour < 12 ? ' AM' : ' PM');
    time = time.length == 10 ? '0'+time : time
    console.log(time);
    this.setState({
      slotvalue: time.toUpperCase(),
    });
  };

  disabledTime = (current, type) => {
    const { startTime, endTime } = this.state;
    let [startHour, startMinute] = startTime ? startTime.split(':') : [];
    let [endHour, endMinute] = endTime ? endTime.split(':') : [];
    startMinute = 30;
    endMinute = 30;
    if (type === 'hour' && startHour && endHour) {
      // Disable hours outside the specified range
      return {
        disabledHours: () => {
          const disabledHours = [];
          for (let i = 0; i < 24; i++) {
            if (i < Number(startHour) - 1 || i > Number(endHour)) {
              disabledHours.push(i);
            }
          }
          return disabledHours;
        },
      };
    }

    return {};
  };

  handleSubmit = () => {
    const time = this.state.slotvalue;
    console.log(time);
    if (time) {
      const slotCheckResult = this.checkWhetherApptDateWithinSlots(`${this.state.value} ${time.toUpperCase()}`);
      if(!slotCheckResult){
        toast.error('The selected Appointment Date/Time is not within the Available Doctor Slots, edit the slots or select different date/time');
        return;
      }
      this.setState({ loader: true });
      this.props
        .submitModal(this.state.dateKey, time, this.state.slotType , this.state.startAppTime, this.state.endAppTime)
        .then(() => {
          this.setState({ loader: false });
          this.props.closeModal();
          this.props.onComplete();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loader: false });
        });
    } else {
      this.setState({ loader: false });
      toast.error('please enter the required fields');
    }
  };
  render() {
    console.log(this.state, 'a slot value', this.state.requestData);
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        toggle={() => this.props.closeModal()}
        className={this.props.className}
      >
        <ModalHeader className="bg-primary" toggle={this.props.closeModal}>
          Confirm Request
        </ModalHeader>
        <ModalBody>
          <form>
            <label>Date:</label>
            <br />
            <input
              type="date"
              value={this.state.appDate}
              disabled={true}
              onChange={this.handleChange}
            />
            <br />
            <label style={{ marginTop: '10px' }}>Slot Type:</label>
            <br />
            <select
              name="slotType"
              id="slotType"
              value={this.state.slotType}
              style={{
                marginBottom: '10px',
              }}
              onChange={(e) => {
                this.setState({
                  slotType: e.target.value,
                });
                if (e.target.value == 'walk-in') {
                  this.handleWalkIn(this.state.startTime);
                }
              }}
            >
             { !this.state.showDoctorNewSlots && <option value="fixed">Fixed Slot</option> }
              <option value="walk-in">Walk-in Slot</option>
            </select>
            <br />
            {this.state.slotType == 'fixed' && (
              <>
                <label>Enter Slot:</label>
                <br />
                <div>
                  <TimePicker
                    onChange={this.slothandleChange}
                    disabledTime={(current) =>
                      this.disabledTime(current, 'hour')
                    }
                    format={this.state.timeformat}
                    inputReadOnly={true}
                    // value={this.state.slotvalue}
                  />
                </div>
              </>
            )}
            {this.state.slotType == 'walk-in' && (
              <>
                <label>Start Time:</label>
                <br />
                <div>
                  <TimePicker
                    onChange={this.handleStartAppTime}
                    format={this.state.timeformat}
                    placeholder='Start Time'
                  />
                </div>
              </>
            )}
            {this.state.slotType == 'walk-in' && (
              <>
                <label>End Time:</label>
                <br />
                <div>
                  <TimePicker
                    onChange={this.handleEndAppTime}
                    format={this.state.timeformat}
                    placeholder='End Time'
                  />
                </div>
              </>
            )}
            {/*
            <select style={{height:'30px',marginLeft:'5px'}} onChange= {this.meredianChange}>
              <option value='AM'>AM</option>
              <option value='PM'>PM</option>
            </select> */}
          </form>
          {this.state.warning && <Warning>{this.state.warning}</Warning>}
          {this.state.doctorSlots ? (
            <SectionInnerContainer>
              <SectionHeader>Doctor Slots &nbsp;
              { !this.state.showDoctorNewSlots && <FaEdit className='button' onClick={()=> {
                  this.setState({doctorSlots:null});
                  this.props.showEditDoctorSlotsModal(this.state.requestData.doctorId)
                  }}/> }
              </SectionHeader>
              {/* <SectionBody>

                {this.state.doctorSlots.map((slot) => (
                  <DetailRow key={slot.day}>
                    <DetailName>{`${slot.day}:`}</DetailName>
                    <DetailText>
                      {slot.values
                        .map((value) => `${value.startTime} - ${value.endTime}`)
                        .join(', ')}
                    </DetailText>
                  </DetailRow>
                ))}
              </SectionBody> */}
            </SectionInnerContainer>
          ) : (
            <Button color="link" onClick={this.getDoctorSlots}>
              Show Doctor Slots
            </Button>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={this.handleSubmit}
            disabled={this.state.loader}
          >
            {this.state.loader && (
              <Spinner
                style={{ height: '16px', width: '16px', marginRight: '5px' }}
              />
            )}
            Confirm
          </Button>
          <Button color="secondary" onClick={this.props.closeModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}


export default OfflineModalConfirm;

