import baseRequest from "./baseRequest"

export const getAllCashlessVaccinationRequests = (params = {}) => {
    const url = '/cashless-vaccination/new-ops/allRequests'
    const method = 'GET'
    return baseRequest(url, method, params, null)
}

export const confirmRequest = (requestId, dateKey, slotId) => {
    const url = `/cashless-vaccination/new-ops/request/${requestId}/confirm`
    const method = 'POST'
    const data = {
        dateKey,
        slotId
    }

    return baseRequest(url, method, null, data)
}

export const cancelRequest = (requestId, reason, remarks) => {
    const url = `/cashless-vaccination/new-ops/request/${requestId}/cancel`
    const method = 'POST'
    const data = {
        cancelReason: reason,
        remarks
    }

    return baseRequest(url, method, null, data)
}

export const getPricingForRequest = (requestId) => {
    const url = `/cashless-vaccination/new-ops/pricingInfo/${requestId}`
    const method = 'GET'
    return baseRequest(url, method, null, null)
}

export const changePriceForRequest = (requestId, pricingData) => {
    const url = '/cashless-vaccination/new-ops/change-price'
    const method = 'POST'
    const data = {
        requestId,
        ...pricingData
    };

    return baseRequest(url, method, null, data)
}

export const callUserVaccinationRequest = (requestId, regarding) => {
    const url = `/cashless-vaccination/new-ops/request/${requestId}/call/user`;
    const method = 'POST';
    const data = {
      regarding,
    };
    return baseRequest(url, method, null, data);
};

export const getVaccinationRequest = (requestId) => {
    const url = `/cashless-vaccination/new-ops/${requestId}/details`
    const method = 'GET'
    return baseRequest(url, method, null, null)
}

export const requestVaccinationPayment = (requestId) => {
    const url = `/cashless-vaccination/new-ops/request-payout/${requestId}`
    const method = 'GET'
    const data = {
        requestId
    }
    return baseRequest(url, method, null, data)
}

export const claimVaccinationRequest = (requestId) => {
    if (!requestId) {
      throw new Error('No Request Id Found');
    }
    const url = `/cashless-vaccination/new-ops/request/${requestId}/claim`;
    const method = 'POST';
    return baseRequest(url, method, null, null);
};

export const shiftVaccinationRequest = (requestId) => {
    if (!requestId) {
        throw new Error('No Request Id Found');
      }
      const url = `/cashless-vaccination/new-ops/request/${requestId}/shift`;
      const method = 'POST';
      return baseRequest(url, method, null, null);
}

export const getVaccinationRequestStatus = (requestId) => {
    const url = `/cashless-vaccination/new-ops/request-status?requestId=${requestId}`
    const method = 'GET'
    return baseRequest(url, method, null, null)
}

export const getVaccinationRequestComments = (requestId) => {
    const url = `/cashless-vaccination/new-ops/comments/${requestId}`
    const method = 'GET'
    return baseRequest(url, method, null, null)
}

export const storeVaccinationComment = (requestId, comment, type = 'internal') => {
    const url = `/cashless-vaccination/new-ops/comments/${requestId}`
    const method = 'POST'
    const data = { comment, type }
    return baseRequest(url, method, null, data)
}

export const getVaccinationRequestHistory = (requestId) => {
    const url = `/cashless-vaccination/new-ops/request-history/${requestId}`
    const method = 'GET'
    return baseRequest(url, method, null, null)
}

export const getVaccinationTransactionHistory = (requestId) => {
    const url = `/cashless-vaccination/new-ops/transaction-history/${requestId}`
    const method = 'GET'
    return baseRequest(url, method, null, null)
}

export const resendCashlessLetter = (requestId) => {
    const url = `/cashless-vaccination/new-ops/resend-cashless-letter/${requestId}`
    const method = 'POST'
    return baseRequest(url, method, null, null)
}