import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Row,
  Col,
  Container,
  Label,
  Spinner,
} from 'reactstrap';

import { toast } from 'react-toastify';

class OfflineModalCancel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      remarks: '',
      applyCancellation: false,
      subCancelReason: '',
      loader: false,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.requestId !== this.props.requestId) {
      this.setState({
        value: '',
        remarks: '',
        applyCancellation: false,
      });
    }
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({
      value,
      subCancelReason: '',
      remarks: '',
    });
  };

  handleSubReasonChange = (e) => {
    this.setState({ subCancelReason: e.target.value });
  };

  handleRemarksInputChange = (e) => {
    this.setState({ remarks: e.target.value });
  };

  handleSubmit = () => {
    const { value, remarks, applyCancellation, subCancelReason } = this.state;
    this.setState({
      loader: true,
    });
    console.log({ value, remarks, applyCancellation }, '[handleSubmit]');
    if (
      value === 'User Requested' &&
      subCancelReason === 'Others' &&
      !remarks
    ) {
      toast.error('Please enter remarks.');
      this.setState({
        loader: false,
      });
      return;
    }
    if (value === 'User Requested' && !subCancelReason) {
      toast.error('Please select a reason to continue');
      this.setState({
        loader: false,
      });
      return;
    }
    if (value === 'User Requested' && subCancelReason != 'Others') {
      return this.props
        .submitModal(value, remarks, applyCancellation, subCancelReason)
        .then(() => {
          this.setState({
            loader: false,
          });
          this.props.closeModal();
          this.props.onComplete();
        });
    }
    if (!value || !remarks) {
      toast.error('No Value selected');
      this.setState({
        loader: false,
      });
      return;
    }
    return this.props
      .submitModal(value, remarks, applyCancellation)
      .then(() => {
        this.setState({
          loader: false,
        });
        this.props.closeModal();
        this.props.onComplete();
      });
  };

  handleApplyCancellation = () => {
    this.setState({
      applyCancellation: !this.state.applyCancellation,
    });
  };

  getRemarksSelectOptions = () => {
    const { value } = this.state;
    switch (value) {
      case 'Doctor Unavailable': {
        return [
          '',
          'Profiling Error',
          'Doctor On Leave',
          'All slots booked',
          'Doctor went for emergency/IPD',
          'Corona',
          'Doctor Unavailable at requested location',
        ];
      }
      case 'Doctor Couldn’t get onboarded': {
        return [
          '',
          'doctor didn’t respond to our calls',
          'Denied 3rd party payment',
        ];
      }
      default:
        return [];
    }
  };

  render() {
    const { value, remarks, subCancelReason } = this.state;
    const remarksSelectOption = this.getRemarksSelectOptions();
    console.log(value, subCancelReason, 'value');
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        toggle={() => this.props.closeModal()}
        className={this.props.className}
        centered
      >
        <ModalHeader
          className="revamp-modal-header"
          toggle={this.props.closeModal}
        >
          Cancel Appointment
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <label
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    width: '100%',
                  }}
                >
                  Are you sure you want to cancel this appointment?
                </label>

                <Row className="mb-2">
                  <Col>
                    <label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      Reason for Cancellation:
                    </label>
                  </Col>
                  <Col>
                    <div>
                      <select
                        style={{
                          fontSize: '12px',
                          fontWeight: 500,
                          border: '1px solid #F2F1F6',
                          padding: '8px',
                          borderRadius: '16px',
                          width: '340px',
                        }}
                        onChange={this.handleChange}
                        value={value}
                      >
                        <option value="">Select Reason</option>
                        <option value="Doctor Unavailable">
                          Doctor Unavailable
                        </option>
                        <option value="Doctor Couldn’t get onboarded">
                          Doctor Couldn’t get onboarded
                        </option>
                        <option value="User Unavailable">
                          User Unavailable
                        </option>
                        <option value="Member already consulted">
                          Member Already Consulted
                        </option>
                        <option value="User Requested">User Requested</option>
                        <option value="Online Consultation Done">
                          Online Consultation Done
                        </option>
                        <option value="Visit Demo">Visit Demo</option>
                        <option value="Request for Nutritionist/ Dietitian">
                          Request for Nutritionist/ Dietitian
                        </option>
                        <option value="API Problem">API Problem</option>
                        <option value="Not covered Under Policy">
                          Not covered Under Policy
                        </option>
                        <option value="Appointment cancelled at hospital end">
                          Appointment cancelled at hospital end
                        </option>
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                      }}
                    >
                      Remarks:
                    </label>
                  </Col>
                  <Col>
                    {value === 'User Requested' && (
                      <>
                        <Row>
                          <div>
                            <select
                              style={{
                                fontSize: '12px',
                                fontWeight: 500,
                                border: '1px solid #F2F1F6',
                                padding: '8px',
                                borderRadius: '16px',
                                margin: '0px 32px 8px 20px',
                                width: '340px',
                              }}
                              onChange={this.handleSubReasonChange}
                              value={subCancelReason}
                            >
                              <option value="">Select Reason</option>
                              <option value="User want to book for alternate center">
                                User want to book for alternate center
                              </option>
                              <option value="User will book for alternate doctor">
                                User will book for alternate doctor
                              </option>
                              <option value="User not ready to pay revised fee">
                                User not ready to pay revised fee
                              </option>
                              <option value="User don't want to pay registration fee">
                                User don't want to pay registration fee
                              </option>
                              <option value="Others">Others</option>
                            </select>
                          </div>
                        </Row>
                        <br />
                      </>
                    )}
                  </Col>
                  {!(
                    value === 'User Requested' && subCancelReason != 'Others'
                  ) && (
                    <>
                      <Row>
                        <div>
                          <React.Fragment>
                            {remarksSelectOption &&
                            remarksSelectOption.length ? (
                              <select
                                style={{
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  border: '1px solid #F2F1F6',
                                  padding: '8px',
                                  borderRadius: '16px',
                                  margin: '0px 32px 8px 20px',
                                  width: '340px',
                                }}
                                value={remarks}
                                onChange={this.handleRemarksInputChange}
                              >
                                {remarksSelectOption.map((option) => (
                                  <option value={option}>
                                    {option || 'Select Remark'}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <input
                                style={{
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  border: '1px solid #F2F1F6',
                                  padding: '8px',
                                  borderRadius: '16px',
                                  margin: '0px 32px 8px 20px',
                                  width: '340px',
                                }}
                                onChange={this.handleRemarksInputChange}
                                value={remarks}
                                placeholder="Please enter remarks"
                              />
                            )}
                          </React.Fragment>
                        </div>
                      </Row>
                      <br />
                    </>
                  )}
                </Row>
                <Row>
                  <Col md={{ size: 5 }}>
                    <Label
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        paddingLeft: '20px',
                      }}
                      check
                    >
                      <Input
                        type="checkbox"
                        onClick={this.handleApplyCancellation}
                      />{' '}
                      Apply Cancellation Fee
                    </Label>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{
              backgroundColor: 'rgb(113, 79, 255)',
              width: '100%',
              borderColor: 'rgb(113, 79, 255)',
              height: '44px',
            }}
            onClick={this.handleSubmit}
            disabled={this.state.loader}
          >
            {this.state.loader && (
              <Spinner
                style={{ height: '16px', width: '16px', marginRight: '5px' }}
              />
            )}
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default OfflineModalCancel;
